import { DocumentReference } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ProfileDoc } from '../../common/collections/Profiles';

type Props = {
  profileRef: DocumentReference<ProfileDoc>;
};

const MyProfileRefContext = createContext<DocumentReference<ProfileDoc> | null>(null);

export const useMyProfileRef = () => {
  const ctx = useContext(MyProfileRefContext);

  if (!ctx) {
    throw new Error('ProfileRefContext');
  }

  return ctx;
};

export default function MyProfileRefProvider({
  children,
  profileRef,
}: PropsWithChildren<Props>) {
  return (
    <MyProfileRefContext.Provider value={profileRef}>
      {children}
    </MyProfileRefContext.Provider>
  );
}
