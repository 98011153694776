import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ProfileHiddenDoc } from '../../common/collections/Profiles';

type Props = {
  profileHiddenSnap: QueryDocumentSnapshot<ProfileHiddenDoc>;
};

// eslint-disable-next-line max-len
const MyProfileHiddenSnapContext = createContext<null | QueryDocumentSnapshot<ProfileHiddenDoc>>(null);

export const useMyProfileHiddenSnap = () => {
  const ctx = useContext(MyProfileHiddenSnapContext);

  if (!ctx) {
    throw new Error('MyProfileHiddenSnapProvider');
  }

  return ctx;
};

export default function MyProfileHiddenSnapProvider({
  children,
  profileHiddenSnap,
}: PropsWithChildren<Props>) {
  return (
    <MyProfileHiddenSnapContext.Provider value={profileHiddenSnap}>
      {children}
    </MyProfileHiddenSnapContext.Provider>
  );
}
