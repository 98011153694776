import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import base64URLdecode from '../../common/base64URLdecode';
import WebAppAction, { WebAppActionType } from '../../common/WebAppAction';

export default function ActionScreen() {
  const data = useMemo<undefined | WebAppAction>(
    () => {
      try {
        const params = new URLSearchParams(Telegram.WebApp.initData);
        const startParam = params.get('start_param');

        if (startParam) {
          const decoded = base64URLdecode(startParam);
          return JSON.parse(decoded) as WebAppAction;
        }

        return undefined;
      } catch (err) {
        return undefined;
      }
    },
    [],
  );

  switch (data?.action) {
    case WebAppActionType.VIEW_APPLICATION: return (
      <Navigate to={`/applications/${data.applicationId}`} />
    );
    case WebAppActionType.VIEW_PROFILE: return (
      <Navigate to={`/profiles/${data.profileId}`} />
    );
    case WebAppActionType.VIEW_TRIP: return (
      <Navigate to={`/trips/${data.tripId}`} />
    );
    case WebAppActionType.VIEW_VENTURE: return (
      <Navigate to={`/ventures/${data.ventureId}`} />
    );
    case WebAppActionType.NEW_TRIP: return (
      <Navigate to="/trips/add" />
    );
    default: return (
      <Navigate to="/profile" />
    );
  }
}
