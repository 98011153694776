/* eslint-disable react/require-default-props */
import {
  Box,
  BoxProps,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { clamp } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  LuHeart,
  LuHexagon,
  LuLayoutGrid,
  LuText,
  LuX,
} from 'react-icons/lu';

import { ApplicationStatus } from '../../../common/collections/Applications';
import { VentureDoc } from '../../../common/collections/Ventures';
import Catch from '../../../components/Catch';
import LogoFull from '../../../components/LogoFull';
import StoragePictureCell from '../../../components/StoragePictureCell';

export type Props = {
  applicationStatus?: ApplicationStatus;
  height: number;
  onExit: () => void;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  queryId?: string;
  searchResultPosition?: number;
  ventureSnap: QueryDocumentSnapshot<VentureDoc>;
  width: number;
} & BoxProps;

export function VentureCardMain({
  applicationStatus,
  height,
  onExit,
  onSwipeLeft,
  onSwipeRight,
  queryId,
  searchResultPosition,
  ventureSnap,
  width,
  ...boxProps
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('TheirVenturesScreen', { keyPrefix: 'Venture' });

  const ventureDoc = useMemo(() => ventureSnap.data(), [ventureSnap]);

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(
    ventureDoc.pictures.length,
    (i) => ({
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: `rgb(from var(--chakra-colors-white) r g b / ${i === 0 ? 1 : (i === 1 ? 0.5 : 0.25)})`,
      display: 'block',
      y: i * height,
    }),
  );

  const bind = useDrag(({
    active, cancel, direction: [,yDir], movement: [,my],
  }) => {
    if (active && Math.abs(my) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (yDir > 0 ? -1 : 1),
        0,
        ventureDoc.pictures.length - 1,
      );
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.25)',
          display: 'none',
        };
      }

      const y = (i - animationIndex.current) * height + (active ? my : 0);
      const backgroundColor = `rgb(from var(--chakra-colors-white) r g b / ${i !== animationIndex.current ? 0.5 : 1})`;
      return {
        backgroundColor,
        display: 'block',
        y,
      };
    });
  }, {
    axis: 'y',
    preventDefault: true,
    threshold: 20,
  });

  const handleDislikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (onSwipeLeft) {
        mixpanel.track('Venture Dislike Clicked');
        onSwipeLeft();
      }
    },
    [onSwipeLeft],
  );

  const handleLikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (onSwipeRight) {
        mixpanel.track('Venture Like Clicked');
        onSwipeRight();
      }
    },
    [onSwipeRight],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      background="var(--chakra-colors-chakra-body-bg)"
      bottom={0}
      className="venture"
      h={`${height}px`}
      left={0}
      position="absolute"
      right={0}
      top={0}
      w={`${width}px`}
    >
      <IconButton
        aria-label={t('exitButton.default')}
        icon={<Icon as={LuLayoutGrid} />}
        onClick={onExit}
        position="absolute"
        right={4}
        size="lg"
        top="max(env(safe-area-inset-top), var(--chakra-space-4))"
        variant="solid"
        zIndex={10}
      />

      <LogoFull
        color="var(--chakra-colors-chakra-body-text)"
        h={12}
        left={4}
        position="absolute"
        top="max(env(safe-area-inset-top), var(--chakra-space-4))"
        w={36}
        zIndex={10}
      />

      <VStack
        alignItems="center"
        bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
        gap={4}
        justifyContent="center"
        position="absolute"
        right={4}
        top="max(env(safe-area-inset-top), var(--chakra-space-4))"
        zIndex={10}
      >
        {pages.map(({ backgroundColor }, i) => (
          <Box
            as={animated.div}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{ color: backgroundColor }}
            transition="color 0.15s ease-in-out"
          >
            {(i < ventureDoc.pictures.length) ? (<Icon as={LuHexagon} boxSize={4} display="block" />) : null}
            {(ventureDoc.pictures.length <= i) ? (<Icon as={LuText} boxSize={4} display="block" />) : null}
          </Box>
        ))}
      </VStack>

      {onSwipeLeft ? (
        <IconButton
          aria-label={t('dislikeButton.default')}
          bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
          icon={<Icon as={LuX} />}
          left={4}
          onClick={handleDislikeClick}
          position="absolute"
          size="lg"
          variant="solid"
          zIndex={10}
        />
      ) : null}

      {onSwipeRight ? (
        <IconButton
          aria-label={t('applyButton.default')}
          bottom="max(env(safe-area-inset-bottom), var(--chakra-space-4))"
          className="ventureAction"
          colorScheme="pink"
          icon={<Icon as={LuHeart} />}
          isDisabled={!!applicationStatus}
          onClick={handleLikeClick}
          position="absolute"
          right={4}
          size="lg"
          variant="solid"
          zIndex={10}
        />
      ) : null}

      <Box
        h={`${height}px`}
        overflow="hidden"
        position="relative"
        ref={ref}
        w={`${width}px`}
      >
        {pages.map(({ display, y }, i) => (
          <StoragePictureCell
            as={animated.div}
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...bind()}
            height={height}
            key={ventureDoc.pictures[i].storageRef}
            picture={ventureDoc.pictures[i]}
            position="absolute"
            style={{
              display: display as unknown as string,
              touchAction: 'none',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              y,
            }}
            width={width}
          />
        ))}
      </Box>
    </Box>
  );
}

export default function VentureCard(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <VentureCardMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
