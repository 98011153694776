import { Container, HStack, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ProfileDoc, ProfileVersionDoc } from '../../common/collections/Profiles';
import { StoragePicture } from '../../common/StoragePicture';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import PicturesFormControl from '../../components/PicturesFormControl';
import { TelegramMainButton } from '../../components/TelegramMainButton';

export type Props = {
  data: Pick<ProfileVersionDoc, 'pictures'> | undefined;
  onComplete: (data: Pick<ProfileVersionDoc, 'pictures'>) => void;
};

export function PicturesFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  // const validatePicture = useValidatePicture();

  // const picturesItemTest = useCallback(async (
  //   value: { imgixUrl: string },
  //   context: yup.TestContext,
  // ) => {
  //   const cache = localStorage.getItem(`validatePicture::${JSON.stringify(value.imgixUrl)}`);

  //   let validationResult: Record<string, boolean>;

  //   try {
  //     if (cache) {
  //       validationResult = JSON.parse(cache) as Record<string, boolean>;
  //     } else {
  //       validationResult = (await validatePicture({ imageUrl: value.imgixUrl })).data;
  //       localStorage.setItem(
  // `validatePicture::${JSON.stringify(value.imgixUrl)}`, JSON.stringify(validationResult));
  //     }
  //   } catch (err) {
  //     throw new yup.ValidationError(
  //       'Unknown error',
  //       value,
  //       context.path,
  //     );
  //   }

  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const k of Object.keys(validationResult)) {
  //     if (!validationResult[k]) {
  //       throw new yup.ValidationError(
  //         t(`pictures.item.${k}Error`),
  //         value,
  //         context.path,
  //       );
  //     }
  //   }

  //   return true;
  // }, [t, validatePicture]);

  // const validateFemalePictures = useValidateFemalePictures();

  // const picturesFemaleTest = useCallback(async (
  //   value: { imgixUrl: string }[],
  //   context: yup.TestContext,
  // ) => {
  //   const imageUrls = _.compact(value.map(({ imgixUrl }) => imgixUrl));
  //   const cache = localStorage.getItem(`validateFemalePictures::${JSON.stringify(imageUrls)}`);

  //   let validationResult: Record<string, boolean>;

  //   try {
  //     if (cache) {
  //       validationResult = JSON.parse(cache) as Record<string, boolean>;
  //     } else {
  //       validationResult = (await validateFemalePictures({ imageUrls })).data;
  //       localStorage.setItem(
  // `validateFemalePictures::${JSON.stringify(imageUrls)}`, JSON.stringify(validationResult));
  //     }
  //   } catch (err) {
  //     throw new yup.ValidationError(
  //       'Unknown error',
  //       value,
  //       context.path,
  //     );
  //   }

  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const k of Object.keys(validationResult)) {
  //     if (!validationResult[k]) {
  //       throw new yup.ValidationError(
  //         t(`pictures.${k}Error`),
  //         value,
  //         context.path,
  //       );
  //     }
  //   }

  //   return true;
  // }, [t, validateFemalePictures]);

  // const validateMalePictures = useValidateMalePictures();

  // const picturesMaleTest = useCallback(async (
  //   value: { imgixUrl: string }[],
  //   context: yup.TestContext,
  // ) => {
  //   const imageUrls = _.compact(value.map(({ imgixUrl }) => imgixUrl));
  //   const cache = localStorage.getItem(`validateMalePictures::${JSON.stringify(imageUrls)}`);

  //   let validationResult: Record<string, boolean>;

  //   try {
  //     if (cache) {
  //       validationResult = JSON.parse(cache) as Record<string, boolean>;
  //     } else {
  //       validationResult = (await validateMalePictures({ imageUrls })).data;
  //       localStorage.setItem(
  // `validateMalePictures::${JSON.stringify(imageUrls)}`, JSON.stringify(validationResult));
  //     }
  //   } catch (err) {
  //     throw new yup.ValidationError(
  //       'Unknown error',
  //       value,
  //       context.path,
  //     );
  //   }

  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const k of Object.keys(validationResult)) {
  //     if (!validationResult[k]) {
  //       throw new yup.ValidationError(
  //         t(`pictures.${k}Error`),
  //         value,
  //         context.path,
  //       );
  //     }
  //   }

  //   return true;
  // }, [t, validateMalePictures]);

  const schema = useMemo(
    () => yup.object().shape({
      pictures: yup
        .array()
        .label(t('pictures.label'))
        .compact()
        .min(3)
        .max(9)
        .required()
        .of(
          yup
            .object()
            .label(t('pictures.item.label'))
            .required()
            .shape({
              blurHash: yup.string(),
              storageRef: yup.string().required(),
            }),
        ),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      pictures: data?.pictures ?? [],
    }),
    [data],
  );

  const handleFormSubmit = useCallback(
    (values: typeof schema['__outputType']) => {
      onComplete(
        {
          pictures: values.pictures as unknown as ProfileDoc['pictures'],
        },
      );
    },
    [onComplete],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Formik<{ pictures: StoragePicture[] }>
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <PicturesFormControl
                  helperText={t('pictures.helperText')}
                  label={t('pictures.label')}
                  name="pictures"
                />
              </VStack>
            </Container>

            <TelegramMainButton
              color={isValid ? '#424242' : '#F44336'}
              isLoading={isValidating || isSubmitting}
              onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                setValidateAll(true);
                handleSubmit();
              }}
              text={isValid ? t('nextButton.default') : t('nextButton.invalid')}
              textColor="#FFFFFF"
            />
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function PicturesForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <PicturesFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
