import {
  Container,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ProfileVersionDoc } from '../../common/collections/Profiles';
import CupSize from '../../common/CupSize';
import PantiesSize from '../../common/PantiesSize';
import CupSizeFormControl from '../../components/CupSizeFormControl';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import NumberFormControl from '../../components/NumberFormControl';
import PantiesSizeFormControl from '../../components/PantiesSizeFormControl';
import { TelegramMainButton } from '../../components/TelegramMainButton';

export type Props = {
  data: Pick<ProfileVersionDoc, 'cupSize' | 'height' | 'pantiesSize' | 'weight'> | undefined;
  onComplete: (data: Pick<ProfileVersionDoc, 'cupSize' | 'height' | 'pantiesSize' | 'weight'>) => void;
};

export function BodyFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      cupSize: yup
        .number()
        .label(t('cupSize.label'))
        .integer()
        .min(CupSize.AA)
        .max(CupSize.E)
        .required(),
      height: yup
        .number()
        .label(t('height.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
      pantiesSize: yup
        .number()
        .label(t('pantiesSize.label'))
        .integer()
        .min(PantiesSize.XXS)
        .max(PantiesSize.XXL)
        .required(),
      weight: yup
        .number()
        .label(t('weight.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      cupSize: data?.cupSize ?? CupSize.AA,
      height: data?.height ?? 170,
      pantiesSize: data?.pantiesSize ?? PantiesSize.XXS,
      weight: data?.weight ?? 50,
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={onComplete}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                  <NumberFormControl
                    label={t('height.label')}
                    max={250}
                    min={150}
                    name="height"
                    right={t('height.unit')}
                    step={1}
                  />

                  <NumberFormControl
                    label={t('weight.label')}
                    max={120}
                    min={45}
                    name="weight"
                    right={t('weight.unit')}
                    step={1}
                  />

                  <GridItem colSpan={2}>
                    <Text
                      _dark={{ color: 'whiteAlpha.600' }}
                      fontSize="sm"
                    >
                      {t('heightAndWeight.helperText')}
                    </Text>
                  </GridItem>
                </Grid>

                <Grid columnGap={4} rowGap={2} templateColumns="repeat(2, 1fr)">
                  <CupSizeFormControl
                    label={t('cupSize.label')}
                    name="cupSize"
                  />

                  <PantiesSizeFormControl
                    label={t('pantiesSize.label')}
                    name="pantiesSize"
                  />

                  <GridItem colSpan={2}>
                    <Text
                      _dark={{ color: 'whiteAlpha.600' }}
                      fontSize="sm"
                    >
                      {t('bikiniSize.helperText')}
                    </Text>
                  </GridItem>
                </Grid>
              </VStack>
            </Container>

            <TelegramMainButton
              color={isValid ? '#424242' : '#F44336'}
              isLoading={isValidating || isSubmitting}
              onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                setValidateAll(true);
                handleSubmit();
              }}
              text={isValid ? t('nextButton.default') : t('nextButton.invalid')}
              textColor="#FFFFFF"
            />
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function BodyForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <BodyFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
