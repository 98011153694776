import {
  Box,
  BoxProps,
  Container,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';

import { getPhotoSizeUrl } from '../common/getPhotoSizeUrl';
import { UnsplashPicture } from '../common/UnsplashPicture';
import useBlur from '../hooks/useBlur';
import { useInsets } from './InsetsProvider';

export type Props = {
  height: number;
  picture: UnsplashPicture;
  width: number;
} & BoxProps;

export default function UnsplashPictureCell({
  height,
  picture,
  width,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const src = useMemo(
    () => getPhotoSizeUrl({
      height,
      uri: picture.urls.raw,
      width,
    }),
    [height, picture, width],
  );

  const fallbackSrc = useBlur({
    blurHash: picture.blur_hash,
    height: 32,
    width: 18,
  });

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Image
        alt=""
        fallbackSrc={fallbackSrc}
        h={`${height}px`}
        objectFit="cover"
        scrollSnapAlign="start"
        scrollSnapStop="always"
        src={src}
        w={`${width}px`}
      />

      {picture ? (
        <Box
          bottom={`calc(${insets.bottom} + var(--chakra-space-1))`}
          left={0}
          position="absolute"
          right={0}
        >
          <Container maxW="lg">
            <Text color="white" fontSize="xs" textAlign="right" textShadow="black 1px 1px 1px" userSelect="none">
              Photo by
              {' '}
              <Link href={`${picture.user.links.html}?utm_source=Qupidu&utm_medium=referral`} target="_blank">
                {picture.user.first_name}
                {' '}
                {picture.user.last_name}
              </Link>
              {' '}
              on
              {' '}
              <Link href="https://unsplash.com/?utm_source=Qupidu&utm_medium=referral" target="_blank">Unsplash</Link>
            </Text>
          </Container>
        </Box>
      ) : null}
    </Box>
  );
}
