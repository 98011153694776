/* eslint-disable react/require-default-props */
import { useEffect } from 'react';

export type Props = {
  color?: false | string;
  hasShineEffect?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  position?: 'bottom' | 'left' | 'right' | 'top';
  text: string;
  textColor?: false | string;
};

export function TelegramSecondaryButton({
  color = false,
  hasShineEffect = false,
  isActive = true,
  isLoading = false,
  onClick,
  position,
  text,
  textColor = false,
}: Props) {
  useEffect(
    () => {
      window.Telegram.WebApp.SecondaryButton.onClick(onClick);

      return () => {
        window.Telegram.WebApp.SecondaryButton.offClick(onClick);
      };
    },
    [onClick],
  );

  useEffect(
    () => {
      window.Telegram.WebApp.SecondaryButton.setParams({
        color,
        has_shine_effect: hasShineEffect,
        position,
        text,
        text_color: textColor,
      });
    },
    [color, hasShineEffect, position, text, textColor],
  );

  useEffect(
    () => {
      if (isLoading) {
        window.Telegram.WebApp.SecondaryButton.showProgress();
      } else {
        window.Telegram.WebApp.SecondaryButton.hideProgress();
      }
    },
    [isLoading],
  );

  useEffect(
    () => {
      if (isActive) {
        window.Telegram.WebApp.SecondaryButton.enable();
      } else {
        window.Telegram.WebApp.SecondaryButton.disable();
      }
    },
    [isActive],
  );

  useEffect(
    () => {
      window.Telegram.WebApp.SecondaryButton.show();

      return () => {
        window.Telegram.WebApp.SecondaryButton.hide();
      };
    },
    [],
  );

  return null;
}
