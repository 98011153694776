import { Center, Grid, Text } from '@chakra-ui/react';
import {
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import DestinationAlgoliaSearchRecord from '../../common/DestinationAlgoliaSearchRecord';
import { useAlgoliaSearchClient } from '../../components/AlgoliaSearchClientProvider';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Destination, { DestinationLoading } from './Destination';

export type Props = {
  query: string;
};

export function DestinationListLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <DestinationLoading />
      <DestinationLoading />
      <DestinationLoading />
      <DestinationLoading />
    </Grid>
  );
}

export function DestinationListMain({
  query,
}: Props) {
  const { t } = useTranslation('TripCreateInitScreen', { keyPrefix: 'DestinationList' });
  const showError = useShowError();

  const client = useAlgoliaSearchClient();
  const destinationsIndex = useMemo(() => client.initIndex('destinations'), [client]);

  const myProfileSnap = useMyProfileSnap();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [destinations, setDestinations] = useState<DestinationAlgoliaSearchRecord[]>([]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      setIsLoading(true);
      destinationsIndex.search<DestinationAlgoliaSearchRecord>(query, {
        clickAnalytics: true,
        // enablePersonalization: true,
        length: 100,
        offset: 0,
        userToken: myProfileSnap.ref.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setDestinations(response.hits);
        ref.current?.scrollTo({ top: 0 });
      }).catch(showError);
    },
    [myProfileSnap.ref.id, showError, query, destinationsIndex],
  );

  if (destinations.length) {
    return (
      <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
        {destinations.map((destinationRecord) => (
          <Destination
            destinationRecord={destinationRecord}
            key={destinationRecord.objectID}
          />
        ))}
      </Grid>
    );
  }

  if (isLoading) {
    return (
      <DestinationListLoading />
    );
  }

  return (
    <Center height="100%">
      <Text textAlign="center">
        {t('emptyList.body')}
      </Text>
    </Center>
  );
}

export default function DestinationList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<DestinationListLoading />}>
        <DestinationListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
