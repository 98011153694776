import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';
import {
  ColorId,
  ContentFilter,
  Language,
  SearchOrderBy,
} from 'unsplash-js';
import { ApiResponse } from 'unsplash-js/dist/helpers/response';
import { Photos } from 'unsplash-js/dist/methods/search/types/response';
import { OrientationParam, PaginationParams, Plus } from 'unsplash-js/dist/types/request';

export type Data = {
  collectionIds?: string[] | undefined;
  color?: ColorId | undefined;
  contentFilter?: ContentFilter | undefined;
  lang?: Language | undefined;
  orderBy?: SearchOrderBy | undefined;
  plus?: Plus | undefined;
} & {
  query: string;
} & OrientationParam & Pick<PaginationParams, 'page' | 'perPage'>;

export type Result = ApiResponse<Photos>;

export default function useUnsplashSearchGetPhotos() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'unsplash-search-getPhotos'), [functions]);
}
