import { VertexAI } from 'firebase/vertexai';
import { createContext, PropsWithChildren, useContext } from 'react';

type Props = {
  sdk: VertexAI;
};

const VertexAIContext = createContext<null | VertexAI>(null);

export const useVertexAI = () => {
  const ctx = useContext(VertexAIContext);

  if (!ctx) {
    throw new Error('VertexAIProvider');
  }

  return ctx;
};

export default function VertexAIProvider({
  children,
  sdk,
}: PropsWithChildren<Props>) {
  return (
    <VertexAIContext.Provider value={sdk}>
      {children}
    </VertexAIContext.Provider>
  );
}
