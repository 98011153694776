import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export default function useShowError() {
  const toast = useToast();

  const showError = useCallback(
    (err: unknown) => {
      if (err instanceof Error) {
        toast({
          description: err.message,
          isClosable: true,
          status: 'error',
          title: 'Error',
        });
      } else {
        toast({
          description: 'Unknown',
          isClosable: true,
          status: 'error',
          title: 'Error',
        });
      }
    },
    [toast],
  );

  return showError;
}
