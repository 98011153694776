export enum WebAppActionType {
  NEW_TRIP = 'new_trip',
  VIEW_APPLICATION = 'view_application',
  VIEW_CONVERSATION = 'view_conversation',
  VIEW_PROFILE = 'view_profile',
  VIEW_TRIP = 'view_trip',
  VIEW_VENTURE = 'view_venture',
}

export type NewTripAction = {
  action: WebAppActionType.NEW_TRIP;
};

export type ViewApplicationAction = {
  action: WebAppActionType.VIEW_APPLICATION;
  applicationId: string;
};

export type ViewConversationAction = {
  action: WebAppActionType.VIEW_CONVERSATION;
  conversationId: string;
};

export type ViewProfileAction = {
  action: WebAppActionType.VIEW_PROFILE;
  profileId: string;
};

export type ViewTripAction = {
  action: WebAppActionType.VIEW_TRIP;
  tripId: string;
};

export type ViewVentureAction = {
  action: WebAppActionType.VIEW_VENTURE;
  ventureId: string;
};

type WebAppAction =
  | NewTripAction
  | ViewApplicationAction
  | ViewConversationAction
  | ViewProfileAction
  | ViewTripAction
  | ViewVentureAction;

export default WebAppAction;
