import { Avatar, AvatarProps } from '@chakra-ui/react';
import { ref } from 'firebase/storage';
import { useStorage, useStorageDownloadURL } from 'reactfire';

import { StoragePicture } from '../common/StoragePicture';
import useBlur from '../hooks/useBlur';

export type Props = {
  picture: StoragePicture;
} & AvatarProps;

export default function StoragePictureAvatar({
  picture, ...avatarProps
}: Props) {
  const storage = useStorage();
  const { data: src } = useStorageDownloadURL(
    ref(storage, picture.storageRef),
    { suspense: false },
  );

  const fallbackSrc = useBlur({
    blurHash: picture.blurHash,
    height: 32,
    width: 18,
  });

  return (
    <Avatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      src={src ?? fallbackSrc}
    />
  );
}
