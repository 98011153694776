import { Container, HStack, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import _ from 'lodash';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ProfileVersionDoc } from '../../common/collections/Profiles';
import Language from '../../common/Language';
import { useInsets } from '../../components/InsetsProvider';
import { useIPData } from '../../components/IPDataProvider';
import LanguagesFormControl from '../../components/LanguagesFormControl';
import LogoFull from '../../components/LogoFull';
import { TelegramMainButton } from '../../components/TelegramMainButton';

export type Props = {
  data: Pick<ProfileVersionDoc, 'languages'> | undefined;
  onComplete: (data: Pick<ProfileVersionDoc, 'languages'>) => void;
};

export function LanguagesFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      languages: yup
        .array(yup
          .string()
          .label(t('languages.item.label'))
          .oneOf(Object.values(Language))
          .required())
        .label(t('languages.label'))
        .min(1)
        .required(),
    }),
    [t],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => {
      const ipDataLanguages: Language[] = ipData?.languages.map(
        (l) => (l as unknown as { code: Language }).code,
      ) ?? [];

      const navigatorLanguages: Language[] = ([navigator.language, ...navigator.languages])
        .map((l) => l.split('-')[0] as Language);

      const languages: Language[] = _.uniq([
        ...ipDataLanguages,
        ...navigatorLanguages,
      ]).sort();

      return {
        languages: data?.languages ?? languages,
      };
    },
    [ipData?.languages, data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={onComplete}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <LanguagesFormControl
                  helperText={t('languages.helperText')}
                  label={t('languages.label')}
                  name="languages"
                  placeholder={t('languages.placeholder')}
                />
              </VStack>
            </Container>

            <TelegramMainButton
              color={isValid ? '#424242' : '#F44336'}
              isLoading={isValidating || isSubmitting}
              onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                setValidateAll(true);
                handleSubmit();
              }}
              text={isValid ? t('nextButton.default') : t('nextButton.invalid')}
              textColor="#FFFFFF"
            />
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function LanguagesForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <LanguagesFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
