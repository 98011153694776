import {
  AspectRatio,
  Box,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';

import DestinationAlgoliaSearchRecord from '../../../common/DestinationAlgoliaSearchRecord';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import Catch from '../../../components/Catch';
import useBlur from '../../../hooks/useBlur';
import DestinationDetails from './DestinationDetails';

export type Props = {
  destinationRecord: DestinationAlgoliaSearchRecord;
};

export function DestinationMain({
  destinationRecord,
}: Props) {
  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 320,
      uri: destinationRecord.picture.urls.raw,
      width: 180,
    }),
    [destinationRecord.picture.urls.raw],
  );

  const fallbackSrc = useBlur({
    blurHash: destinationRecord.picture.blur_hash,
    height: 32,
    width: 18,
  });

  return (
    <AspectRatio ratio={9 / 16}>
      <Box
        as={Link}
        borderRadius="md"
        boxShadow="base"
        h="100%"
        overflow="hidden"
        position="relative"
        to={`./${destinationRecord.objectID}`}
        w="100%"
      >
        <Image
          fallbackSrc={fallbackSrc}
          h="100%"
          objectFit="cover"
          position="absolute"
          src={src}
          w="100%"
        />

        <DestinationDetails
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          bottom={0}
          destinationRecord={destinationRecord}
          left={0}
          position="absolute"
          right={0}
        />
      </Box>
    </AspectRatio>
  );
}

export function DestinationLoading() {
  return (
    <AspectRatio ratio={9 / 16}>
      <Skeleton h="100%" w="100%" />
    </AspectRatio>
  );
}

export default function Destination(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<DestinationLoading />}>
        <DestinationMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
