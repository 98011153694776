import {
  Container,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ProfileVersionDoc,
  ProfileVersionRejectionReason,
} from '../../common/collections/Profiles';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import { TelegramMainButton } from '../../components/TelegramMainButton';
import { TelegramSecondaryButton } from '../../components/TelegramSecondaryButton';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
  onReapplyClick: () => void;
};

export default function ProfileVersionRejected({ lastProfileVersionSnap, onReapplyClick }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileVersionRejected' });

  const lastProfileVersionDoc = useMemo(
    () => lastProfileVersionSnap.data(),
    [lastProfileVersionSnap],
  );

  const insets = useInsets();

  const onContactSupportClick = useCallback(() => {
    window.Telegram.WebApp.openTelegramLink('https://t.me/longuniquename');
  }, []);

  const rejectionReason = useMemo(
    () => {
      switch (lastProfileVersionDoc.rejectionReason) {
        case ProfileVersionRejectionReason.INSTAGRAM_VERIFICATION: return t('rejectionReason.INSTAGRAM_VERIFICATION');
        case ProfileVersionRejectionReason.NO_BIKINI_PICTURES: return t('rejectionReason.NO_BIKINI_PICTURES');
        case ProfileVersionRejectionReason.PICTURES_CONTENT: return t('rejectionReason.PICTURES_CONTENT');
        case ProfileVersionRejectionReason.PICTURES_QUALITY: return t('rejectionReason.PICTURES_QUALITY');
        default: return t('rejectionReason.OTHER');
      }
    },
    [lastProfileVersionDoc.rejectionReason, t],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        flexShrink={0}
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-4))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        minH={0}
        overflow="auto"
        py={2}
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {t('text1', { name: lastProfileVersionDoc.name })}
          </Text>

          <Text textAlign="center">
            {t('text2')}
          </Text>

          <Text fontSize="sm" textAlign="center">
            {rejectionReason}
          </Text>
        </VStack>
      </Container>

      <TelegramSecondaryButton
        color="#424242"
        onClick={onContactSupportClick}
        position="top"
        text={t('contactSupportButton.default')}
        textColor="#FFFFFF"
      />

      <TelegramMainButton
        color="#9C27B0"
        hasShineEffect
        onClick={() => {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
          onReapplyClick();
        }}
        text={t('reapplyButton.default')}
        textColor="#FFFFFF"
      />
    </VStack>
  );
}
