import {
  AspectRatio,
  AvatarGroup,
  Box,
  BoxProps,
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { animated, useScroll, useSpring } from '@react-spring/web';
import {
  doc,
  DocumentReference,
  getCountFromServer,
  getDoc,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  refEqual,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { clamp, compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  CSSProperties,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { LuHeart, LuMapPin } from 'react-icons/lu';
import Markdown, { Components } from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

import AppLanguage from '../../../common/AppLanguage';
import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../../common/collections/Applications';
import { ProfileDoc } from '../../../common/collections/Profiles';
import { TripDoc } from '../../../common/collections/Trips';
import { useInsets } from '../../../components/InsetsProvider';
import LogoFull from '../../../components/LogoFull';
import LogoIcon from '../../../components/LogoIcon';
import ProfileAvatar from '../../../components/ProfileAvatar';
import { useMyProfileRef } from '../../../components/refProviders/MyProfileRefProvider';
import StoragePictureImage from '../../../components/StoragePictureImage';
import { TelegramMainButton } from '../../../components/TelegramMainButton';
import UnsplashPictureCell from '../../../components/UnsplashPictureCell';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import useShowError from '../../../hooks/useShowError';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import i18n from '../../../i18n';
import ParticipantCard from './ParticipantCard';

/* eslint-disable react/jsx-props-no-spreading */
const components: Components = {
  a: ({ node, ...props }) => (<Link isExternal {...props} />),
  h1: ({ node, ...props }) => (<Heading as="h1" {...props} />),
  h2: ({ node, ...props }) => (<Heading as="h2" {...props} />),
  h3: ({ node, ...props }) => (<Heading as="h3" {...props} />),
  li: ({ node, ...props }) => (<ListItem {...props} />),
  p: ({ node, ...props }) => (<Text as="p" {...props} />),
  strong: ({ node, ...props }) => (<Text as="strong" {...props} />),
  ul: ({ node, ...props }) => (<UnorderedList {...props} />),
};
/* eslint-enable react/jsx-props-no-spreading */

export type Props = {
  tripSnap: QueryDocumentSnapshot<TripDoc>;
} & BoxProps;

function useApplicationStatus(
  tripRef: DocumentReference<TripDoc>,
  profileRef: DocumentReference<ProfileDoc> | undefined,
): boolean {
  const [
    currentVentureRef,
    setCurrentVentureRef,
  ] = useState<DocumentReference<TripDoc>>(tripRef);

  const [
    currentProfileRef,
    setCurrentProfileRef,
  ] = useState<DocumentReference<ProfileDoc> | undefined>(profileRef);

  useEffect(
    () => {
      if (!refEqual(tripRef, currentVentureRef)) {
        setCurrentVentureRef(tripRef);
      }
    },
    [currentVentureRef, tripRef],
  );

  useEffect(
    () => {
      if (!profileRef) {
        setCurrentProfileRef(undefined);
      } else if (!currentProfileRef) {
        setCurrentProfileRef(profileRef);
      } else if (!refEqual(profileRef, currentProfileRef)) {
        setCurrentProfileRef(profileRef);
      }
    },
    [currentProfileRef, profileRef],
  );

  const [applicationExists, setApplicationExists] = useState<boolean>(false);

  const applicationsCollectionRef = useApplicationsCollectionRef();

  useEffect(
    () => {
      if (currentProfileRef) {
        return onSnapshot(
          query(
            applicationsCollectionRef,
            where('applicantRef', '==', currentProfileRef),
            where('subjectRef', '==', currentVentureRef),
          ),
          (snap) => {
            setApplicationExists(snap.docs.length > 0);
          },
        );
      }

      setApplicationExists(false);
      return () => { };
    },
    [applicationsCollectionRef, currentProfileRef, currentVentureRef],
  );

  return applicationExists;
}

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        contentSafeAreaInset: {
          top: number;
        };
        safeAreaInset: {
          top: number;
        };
        shareMessage: (msg_id: string, callback?: (success: boolean) => void) => void;
      };
    };
  }
}

export function TripRowMain({
  tripSnap, ...boxProps
}: Props) {
  const { t } = useTranslation('TripScreen', { keyPrefix: 'Trip' });

  useEffect(
    () => {
      window.Telegram.WebApp.disableVerticalSwipes();

      return () => {
        window.Telegram.WebApp.enableVerticalSwipes();
      };
    },
    [],
  );

  const myProfileRef = useMyProfileRef();

  const applicationsCollectionRef = useApplicationsCollectionRef();

  const [applicationsCount, setApplicationsCount] = useState<number>(0);
  useEffect(
    () => {
      getCountFromServer(
        query(
          applicationsCollectionRef,
          where('subjectRef', '==', tripSnap.ref),
        ),
      )
        .then((data) => setApplicationsCount(data.data().count))
        .catch(() => { });
    },
    [applicationsCollectionRef, tripSnap.ref],
  );

  const showError = useShowError();
  const navigate = useNavigate();
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const onApplyClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setIsApplying(true);

      (async () => {
        mixpanel.track('Trip Like Clicked');

        const myProfileSnap = await getDoc(myProfileRef);

        if (!myProfileSnap.exists()) {
          navigate('/welcome');
          return;
        }

        const tripDoc = tripSnap.data();

        const applicationRef = doc(applicationsCollectionRef);

        await setDoc(
          applicationRef,
          {
            _v: 1,
            applicantRef: myProfileRef,
            organizerRef: tripDoc.organizerRef,
            sentAt: Timestamp.now(),
            status: ApplicationStatus.SENT,
            subjectRef: tripSnap.ref,
          },
        );
      })()
        .finally(() => setIsApplying(false))
        .catch(showError);
    },
    [applicationsCollectionRef, myProfileRef, navigate, showError, tripSnap],
  );

  const applicationExists = useApplicationStatus(tripSnap.ref, myProfileRef);

  // const showError = useShowError();
  // const telegramPrepareTripMessage = useTelegramPrepareTripMessage();
  // const handleShare = useCallback(
  //   () => {
  //     if (window.Telegram.WebApp.initDataUnsafe.user?.id) {
  //       telegramPrepareTripMessage({
  //         telegramUserId: window.Telegram.WebApp.initDataUnsafe.user?.id,
  //         tripId: tripSnap.id,
  //       })
  //         .then(({ data: { id } }) => {
  //           window.Telegram.WebApp.shareMessage(id, (sent: boolean) => {
  //             window.Telegram.WebApp.showAlert(sent ? 'True' : 'False');
  //           });
  //         })
  //         .catch(showError);
  //     }
  //   },
  //   [showError, telegramPrepareTripMessage, tripSnap.id],
  // );

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const { snap: destinationSnap } = useDocumentSnapshot(tripDoc.destinationRef);
  const destinationDoc = useMemo(() => destinationSnap?.data(), [destinationSnap]);

  const { snap: organizerSnap } = useDocumentSnapshot(tripDoc.organizerRef);
  const organizerDoc = useMemo(() => organizerSnap?.data(), [organizerSnap]);

  const pictures = useMemo(
    () => tripDoc.pictures.filter(({ storageRef }) => !!storageRef),
    [tripDoc.pictures],
  );

  const insets = useInsets();

  const { height: wh, width: ww } = useWindowDimensions();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const containerRef = useRef<HTMLDivElement>(null!);

  const [coverStyles, coverApi] = useSpring(() => ({
    y: 0,
  }));

  const [notchStyles, notchApi] = useSpring(() => ({
    height: 0,
  }));

  useScroll({
    container: containerRef,
    default: {
      immediate: true,
    },
    onChange: ({ value: { scrollY } }) => {
      coverApi.start({
        config: {
          clamp: true,
          friction: 20,
          tension: 300,
        },
        y: -(clamp(scrollY as number, 0, window.Telegram.WebApp.viewportHeight)) / 2,
      });

      notchApi.start({
        config: {
          clamp: true,
          friction: 20,
          tension: 300,
        },
        height: clamp(
          scrollY as number,
          window.Telegram.WebApp.viewportHeight / 4,
          window.Telegram.WebApp.viewportHeight / 4
          + Math.max(window.Telegram.WebApp.safeAreaInset.top, 8)
          + 48,
        ) - window.Telegram.WebApp.viewportHeight / 4,
      });
    },
  });

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
      position="relative"
    >
      <HStack
        h={12}
        justifyContent="center"
        left={insets.left}
        position="absolute"
        right={insets.right}
        top={`max(${insets.top}, var(--chakra-space-2))`}
        zIndex={100}
      >
        <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
      </HStack>

      <Box
        as={animated.div}
        backgroundColor="var(--chakra-colors-chakra-body-bg)"
        left={0}
        position="absolute"
        right={0}
        style={notchStyles as unknown as CSSProperties}
        top={0}
        zIndex={90}
      />

      <UnsplashPictureCell
        as={animated.div}
        height={wh}
        picture={tripDoc.picture}
        position="absolute"
        style={coverStyles as unknown as CSSProperties}
        width={ww}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        gap={0}
        h="100%"
        overflowY="auto"
        ref={containerRef}
      >
        <VStack
          alignItems="stretch"
          flexGrow={0}
          flexShrink={0}
          h="calc(var(--tg-viewport-height) - 100px)"
          justifyContent="end"
        >
          <VStack
            alignItems="center"
            background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
            gap={2}
            py={10}
          >
            <AvatarGroup max={6} size="sm">
              {tripDoc.femaleParticipants.map((participantRef) => (
                <ProfileAvatar key={participantRef.id} profileRef={participantRef} />
              ))}

              {tripDoc.maleParticipants.map((participantRef) => (
                <ProfileAvatar key={participantRef.id} profileRef={participantRef} />
              ))}
            </AvatarGroup>

            <Container maxW="lg">
              <Heading
                as="h1"
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
              >
                {tripDoc.name}
              </Heading>
            </Container>

            <Text
              opacity={0.75}
            >
              {t('dates.value', {
                endsAt: tripDoc.endsAt.toDate(),
                formatParams: {
                  endsAt: { day: 'numeric', month: 'short', year: 'numeric' },
                  startsAt: { day: 'numeric', month: 'short' },
                },
                startsAt: tripDoc.startsAt.toDate(),
              })}
            </Text>
          </VStack>
        </VStack>

        <Box
          backgroundColor="var(--chakra-colors-chakra-body-bg)"
          flexGrow={0}
          flexShrink={0}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
        >
          <Container maxW="lg">
            <VStack
              alignItems="stretch"
              gap={4}
            >
              <Markdown components={components} remarkPlugins={[remarkGfm]}>
                {/* eslint-disable-next-line max-len */}
                {tripDoc.translations?.description[i18n.language as AppLanguage] ?? tripDoc.description}
              </Markdown>

              <Grid gap={1} templateColumns="repeat(2, auto)">
                <HStack gap={1} justifySelf="start" maxW="100%" minW={0} opacity={0.75}>
                  <Icon as={LuMapPin} boxSize={3} />
                  <Text
                    fontSize="sm"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {compact([destinationDoc?.name.en, destinationDoc?.countryName.en]).join(', ')}
                  </Text>
                </HStack>

                <HStack gap={1} justifySelf="end" opacity={0.75}>
                  <Icon as={LuHeart} boxSize={3} />
                  <Text
                    fontSize="sm"
                    whiteSpace="nowrap"
                  >
                    {t('applicationsCount', { count: applicationsCount })}
                  </Text>
                </HStack>
              </Grid>

              {pictures.length ? (
                <Grid
                  autoRows="1fr"
                  gap={2}
                  templateColumns="repeat(3, 1fr)"
                >
                  {pictures.map((picture) => (
                    <GridItem key={picture.storageRef}>
                      <AspectRatio ratio={9 / 16}>
                        <StoragePictureImage
                          borderRadius="md"
                          cursor="pointer"
                          h="100%"
                          objectFit="cover"
                          picture={picture}
                        />
                      </AspectRatio>
                    </GridItem>
                  ))}

                  {new Array(Math.ceil(pictures.length / 3) * 3 - pictures.length)
                    .fill(null)
                    .map((_, i) => (
                      <Box
                        _dark={{
                          backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        }}
                        _light={{
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        }}
                        borderRadius="md"
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                      />
                    ))}
                </Grid>
              ) : null}

              <Grid gap={2} templateColumns="repeat(2, 1fr)">
                <GridItem
                  _dark={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  }}
                  _light={{
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  }}
                  borderRadius="md"
                  p={2}
                >
                  <VStack
                    alignItems="stretch"
                    h="100%"
                  >
                    <Grid gap={1} templateColumns="repeat(3, auto)">
                      <Text
                        fontSize="xs"
                        justifySelf="start"
                        opacity={0.75}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {t('femaleParticipants.label')}
                      </Text>

                      <Text
                        fontSize="xs"
                        justifySelf="center"
                        opacity={0.75}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {t('femalePrice.value', {
                          amount: tripDoc.femaleParticipantPrice.amount,
                          formatParams: {
                            amount: { currency: tripDoc.femaleParticipantPrice.currency },
                          },
                        })}
                      </Text>

                      <Text
                        fontSize="xs"
                        justifySelf="end"
                        opacity={0.75}
                      >
                        {tripDoc.femaleParticipants.length}
                        /
                        {tripDoc.femaleParticipantPlaces}
                      </Text>
                    </Grid>
                  </VStack>
                </GridItem>

                <GridItem
                  _dark={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  }}
                  _light={{
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  }}
                  borderRadius="md"
                  p={2}
                >
                  <VStack
                    alignItems="stretch"
                    h="100%"
                  >
                    <Grid gap={1} templateColumns="repeat(3, auto)">
                      <Text
                        fontSize="xs"
                        justifySelf="start"
                        opacity={0.75}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {t('maleParticipants.label')}
                      </Text>

                      <Text
                        fontSize="xs"
                        justifySelf="center"
                        opacity={0.75}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {t('malePrice.value', {
                          amount: tripDoc.maleParticipantPrice.amount,
                          formatParams: {
                            amount: { currency: tripDoc.maleParticipantPrice.currency },
                          },
                        })}
                      </Text>

                      <Text
                        fontSize="xs"
                        justifySelf="end"
                        opacity={0.75}
                      >
                        {tripDoc.maleParticipants.length}
                        /
                        {tripDoc.maleParticipantPlaces}
                      </Text>
                    </Grid>
                  </VStack>
                </GridItem>

                <GridItem
                  _dark={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  }}
                  _light={{
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  }}
                  borderRadius="md"
                  colSpan={2}
                  p={2}
                >
                  <Text
                    fontSize="xs"
                    opacity={0.75}
                  >
                    {t('femaleParticipantTicketCoverage.value', {
                      count: tripDoc.femaleParticipantTicketCoverage,
                    })}
                  </Text>
                </GridItem>
              </Grid>

              {organizerDoc ? (
                <VStack alignItems="stretch" gap={2}>
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    opacity={0.75}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {t('organizer.label')}
                  </Text>

                  <Grid gap={2} templateColumns="1fr 2fr">
                    <ParticipantCard
                      profileRef={tripDoc.organizerRef}
                    />

                    <GridItem
                      _dark={{
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      }}
                      _light={{
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      }}
                      borderRadius="md"
                      p={2}
                    >
                      <VStack alignItems="stretch" spacing={1}>
                        <HStack>
                          <Text
                            flex={1}
                            fontSize="lg"
                            fontWeight="bold"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            {organizerDoc.name}
                            {', '}
                            {organizerDoc.age}
                          </Text>

                          {organizerDoc.instagramTag ? (
                            <IconButton
                              aria-label="Instagram"
                              as="a"
                              href={`https://www.instagram.com/${organizerDoc.instagramTag}`}
                              icon={<Icon as={FaInstagram} />}
                              size="sm"
                              target="_blank"
                            />
                          ) : null}

                          {organizerDoc.tiktokTag ? (
                            <IconButton
                              aria-label="TikTok"
                              as="a"
                              href={`https://www.tiktok.com/@${organizerDoc.tiktokTag}`}
                              icon={<Icon as={FaTiktok} />}
                              size="sm"
                              target="_blank"
                            />
                          ) : null}

                          {organizerDoc.linkedinTag ? (
                            <IconButton
                              aria-label="LinkedIn"
                              as="a"
                              href={`https://www.linkedin.com/in/${organizerDoc.linkedinTag}`}
                              icon={<Icon as={FaLinkedin} />}
                              size="sm"
                              target="_blank"
                            />
                          ) : null}
                        </HStack>

                        <Text>
                          {organizerDoc.description}
                        </Text>
                      </VStack>
                    </GridItem>
                  </Grid>
                </VStack>
              ) : null}

              <VStack alignItems="stretch" gap={2}>
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  opacity={0.75}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {t('participants.label')}
                </Text>

                {(tripDoc.femaleParticipants.length + tripDoc.maleParticipants.length) > 0 ? (
                  <Grid
                    autoRows="1fr"
                    gap={2}
                    templateColumns="repeat(3, 1fr)"
                  >
                    {tripDoc.femaleParticipants.map((participantRef) => (
                      <ParticipantCard profileRef={participantRef} />
                    ))}
                    {tripDoc.maleParticipants.map((participantRef) => (
                      <ParticipantCard profileRef={participantRef} />
                    ))}

                    {new Array(
                      Math.ceil(
                        (tripDoc.femaleParticipants.length + tripDoc.maleParticipants.length) / 3,
                      ) * 3 - (tripDoc.femaleParticipants.length + tripDoc.maleParticipants.length),
                    )
                      .fill(null)
                      .map((_, i) => (
                        <Box
                          _dark={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                          }}
                          _light={{
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                          }}
                          borderRadius="md"
                        // eslint-disable-next-line react/no-array-index-key
                          key={i}
                        />
                      ))}
                  </Grid>
                ) : null}
              </VStack>

              {!applicationExists ? (
                <TelegramMainButton
                  color="#9C27B0"
                  hasShineEffect
                  isLoading={isApplying}
                  onClick={onApplyClick}
                  text={t('applyButton.default')}
                  textColor="#FFFFFF"
                />
              ) : null}
            </VStack>
          </Container>
        </Box>
      </VStack>
    </Box>
  );
}

export default function TripRow({ tripSnap, ...boxProps }: Props) {
  return (
    <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
      <TripRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...boxProps}
        tripSnap={tripSnap}
      />
    </Suspense>
  );
}
