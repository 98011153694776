import {
  Box,
  Center,
  Container,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuAlertTriangle } from 'react-icons/lu';
import { useParams } from 'react-router-dom';

import { useProfilesCollectionRef } from '../../common/collections/Profiles';
import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import LogoIcon from '../../components/LogoIcon';
import ProfileCard from '../../components/ProfileCard';
import useDimensions from '../../hooks/useDimensions';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function ProfileScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'ProfileScreen' }); }, []);

  const { profileId } = useParams<{ profileId: string }>();

  const { t } = useTranslation('ProfileScreen');

  const insets = useInsets();

  const [ref, { height, width }] = useDimensions();

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => doc(profilesCollectionRef, profileId),
    [profileId, profilesCollectionRef],
  );

  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  if (!profileSnap || (!profileSnap.exists() && profileSnap.metadata.fromCache)) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!profileSnap?.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Profile not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <Box
        h="100%"
        position="relative"
      >
        <Box
          left={0}
          position="absolute"
          right={0}
          top={`max(${insets.top}, var(--chakra-space-2))`}
          zIndex={50}
        >
          <Container maxW="lg">
            <HStack h={12} justifyContent="center">
              <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
            </HStack>
          </Container>
        </Box>

        <InsetsProvider
          bottom={insets.bottom}
          left={insets.left}
          right={insets.right}
          top={`calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10))`}
        >
          <Container h="100%" maxW="lg" px={0} ref={ref}>
            {height && width ? (
              <ProfileCard
                height={height}
                profileSnap={profileSnap}
                width={width}
              />
            ) : null}
          </Container>
        </InsetsProvider>
      </Box>
    </>
  );
}

export default function ProfileScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileScreenMain />
      </Suspense>
    </Catch>
  );
}
