import {
  HStack,
  Skeleton,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import { Suspense, useMemo } from 'react';
import { useFirestoreDocOnce } from 'reactfire';

import { AirportDoc } from '../../common/collections/Airports';
import Catch from '../Catch';

export type Props = {
  airportRef: DocumentReference<AirportDoc>;
};

export function AirportRowMain({ airportRef }: Props) {
  const { data: airportSnap } = useFirestoreDocOnce(airportRef);

  if (!airportSnap.exists()) {
    throw new Error('Airport does not exist');
  }

  const airportDoc = useMemo(() => airportSnap.data(), [airportSnap]);

  const [mono] = useToken('fonts', ['mono']);

  return (
    <HStack flex={1} spacing={4} userSelect="none">
      <Text fontFamily={mono} fontSize="4xl" lineHeight={1}>
        {airportRef.id}
      </Text>

      <VStack alignItems="stretch" flex={1} spacing={1}>
        <Text fontSize="sm" lineHeight={1}>
          {airportDoc.name}
        </Text>
        <Text fontSize="xs" lineHeight={1}>
          {_.compact([airportDoc.countryEmoji, _.compact([
            airportDoc.cityName,
            airportDoc.countryName,
          ]).join(', ')]).join(' ')}
        </Text>
      </VStack>
    </HStack>
  );
}

export function AirportRowLoading() {
  return (
    <HStack spacing={4} width="100%">
      <Skeleton h="30px" w="60px" />

      <VStack alignItems="stretch" flex={1} spacing={1}>
        <Skeleton h="14px" w="100%" />
        <Skeleton h="12px" w="100%" />
      </VStack>
    </HStack>
  );
}

export default function AirportRow(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<AirportRowLoading />}>
        <AirportRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
