const getCroppedImage = async (img: HTMLImageElement): Promise<{ blob:Blob, dataUrl: string }> => {
  let width: number = img.naturalWidth;
  let height: number = Math.round((img.naturalWidth / 9) * 16);
  let x = 0;
  let y: number = -Math.round((img.naturalHeight - height) / 2);

  if (img.naturalWidth / img.naturalHeight > 9 / 16) {
    height = img.naturalHeight;
    width = Math.round((img.naturalHeight / 16) * 9);
    y = 0;
    x = -Math.round((img.naturalWidth - width) / 2);
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('2d context is not available');
  }

  ctx.drawImage(img, x, y);

  ctx.save();

  const blob = await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((result) => {
      if (!result) {
        reject(new Error('Unknown'));
        return;
      }

      resolve(result);
    }, 'image/webp');
  });

  const dataUrl = canvas.toDataURL('image/webp');

  return { blob, dataUrl };
};

export default getCroppedImage;
