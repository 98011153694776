import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions
import DecodeBlurHashWorker from 'worker-loader!./decode.worker.js';

export async function getBlur({
  blurHash,
  height,
  width,
}: {
  blurHash: string,
  height: number,
  width: number,
}): Promise<string> {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('2d context is not available');
  }

  const pixels = await new Promise<Uint8ClampedArray>((res, rej) => {
    const worker = new DecodeBlurHashWorker();

    worker.addEventListener('message', (e: { data: { pixels: Uint8ClampedArray } }) => {
      res(e.data.pixels);
    });
    worker.addEventListener('error', (e) => {
      rej(e);
    });
    worker.postMessage({ payload: { blurHash, height, width } });
  });

  const imageData = new ImageData(pixels, width, height);

  imageData.data.set(pixels);
  ctx.putImageData(imageData, 0, 0);

  return canvas.toDataURL('image/webp');
}

export default function useBlur({
  blurHash,
  height,
  width,
}: {
  blurHash: null | string | undefined,
  height: number,
  width: number,
}): string | undefined {
  const [blur, setBlur] = useState<string | undefined>(() => {
    try {
      return (blurHash && localStorage.getItem(`blurHash/${blurHash}`)) ?? undefined;
    } catch (err) {
      return undefined;
    }
  });

  useEffect(
    () => {
      if (blurHash) {
        getBlur({
          blurHash,
          height,
          width,
        }).then((data) => {
          setBlur(data);
          try {
            localStorage.setItem(`blurHash/${blurHash}`, data);
          } catch (err) {
          /* do nothing */
          }
        }).catch(() => { });
      } else {
        setBlur(undefined);
      }
    },
    [blurHash, height, width],
  );

  return blur;
}
