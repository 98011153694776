import { getGenerativeModel, Schema } from 'firebase/vertexai';
import { useCallback, useMemo } from 'react';

import AppLanguage from '../common/AppLanguage';
import destinationLabels from '../common/destinationLabels';
import { useVertexAI } from '../components/VertexAIProvider';

export default function useGenerateDestination() {
  const vertexAI = useVertexAI();

  const model = useMemo(
    () => getGenerativeModel(
      vertexAI,
      {
        generationConfig: {
          responseMimeType: 'application/json',
          responseSchema: Schema.object({
            properties: {
              countryISOKey: Schema.string({
                description: 'The ISO 3166-1 alpha-2 code for the country.',
              }),
              countryName: Schema.object({
                description: 'The name of the country where the destination is located.',
                properties: {
                  de: Schema.string(),
                  en: Schema.string(),
                  es: Schema.string(),
                  fr: Schema.string(),
                  it: Schema.string(),
                  nl: Schema.string(),
                  pl: Schema.string(),
                  pt: Schema.string(),
                  ru: Schema.string(),
                  tr: Schema.string(),
                  uk: Schema.string(),
                },
              }),
              destinationDescription: Schema.object({
                description: 'A captivating and enticing travel description tailored for young Instagram models.',
                properties: {
                  de: Schema.string(),
                  en: Schema.string(),
                  es: Schema.string(),
                  fr: Schema.string(),
                  it: Schema.string(),
                  nl: Schema.string(),
                  pl: Schema.string(),
                  pt: Schema.string(),
                  ru: Schema.string(),
                  tr: Schema.string(),
                  uk: Schema.string(),
                },
              }),
              destinationLabels: Schema.array({
                description: 'An array of applicable labels selected from the predefined list.',
                items: Schema.string(),
              }),
              destinationName: Schema.object({
                description: "The name of the trip destination, e.g., 'Paris', 'Maldives', etc.",
                properties: {
                  de: Schema.string(),
                  en: Schema.string(),
                  es: Schema.string(),
                  fr: Schema.string(),
                  it: Schema.string(),
                  nl: Schema.string(),
                  pl: Schema.string(),
                  pt: Schema.string(),
                  ru: Schema.string(),
                  tr: Schema.string(),
                  uk: Schema.string(),
                },
              }),
            },
          }),
          temperature: 0.5,
        },
        model: 'gemini-1.5-flash',
      },
    ),
    [vertexAI],
  );

  return useCallback(
    async ({ destinationName }: { destinationName: string; }) => {
      const result = await model.generateContent(`
{{role "system"}}
Generate a captivating travel description for an Instagram-worthy luxury trip, targeting young Instagram models with no budget limits. The description should evoke a sense of exclusivity, luxury, and adventure, highlighting the most glamorous and photogenic aspects of the destination. Incorporate the following elements:

1. Describe the most luxurious experiences available at this destination, such as five-star accommodations, fine dining, exclusive shopping, and unique experiences (e.g., yacht parties, private tours, spa treatments).
2. Emphasize the destination's Instagrammable spots, including stunning landscapes, iconic landmarks, and trendy hotspots.
3. Convey a sense of excitement and exclusivity, making the reader feel like they are being offered a once-in-a-lifetime opportunity.
4. Highlight the potential for social media content creation, mentioning how the destination's aesthetics and experiences can boost their online presence.

Check the destination name and rewrite it to widely used name in target language if needed.

After generating the description and updating the destination name, do the following:
- Provide the name of the country where the destination is located.
- Provide the ISO country code (ISO 3166-1 alpha-2 format) for the country.
- Select all applicable labels from the user-defined list.
- Translate destination name, country name, and description to 10 languages: 'de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'ru', 'tr', 'uk'

{{role "user"}}
- **Destination Name**: ${destinationName}
- **Possible Destination Labels**: ${destinationLabels.join(', ')}
  `);

      return JSON.parse(result.response.text()) as {
        countryISOKey: string;
        countryName: Record<AppLanguage, string>;
        destinationDescription: Record<AppLanguage, string>;
        destinationLabels: string[];
        destinationName: Record<AppLanguage, string>;
      };
    },
    [model],
  );
}
