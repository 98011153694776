/* eslint-disable react/require-default-props */
import {
  Box,
  BoxProps,
  Button,
  Container,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDoc } from 'reactfire';

import { ProfileDoc } from '../../common/collections/Profiles';
import { cupSizeLabels } from '../../common/CupSize';
import Gender from '../../common/Gender';
import { hairLengthLabels } from '../../common/HairLength';
import { pantiesSizeLabels } from '../../common/PantiesSize';
import { wealthLabels } from '../../common/Wealth';

export type Props = {
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
  showPrivate?: boolean;
} & BoxProps;

export default function InfoCell({
  profileSnap,
  showPrivate = false,
  ...boxProps
}: Props) {
  const { t: lt } = useTranslation('Languages');
  const { t: zt } = useTranslation('Zodiac');
  const { t: cst } = useTranslation('CupSize');
  const { t: pst } = useTranslation('PantiesSize');
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');
  const { t: hlt } = useTranslation('HairLength');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: st } = useTranslation('Sexuality');
  const { t: wt } = useTranslation('Wealth');

  const applicantDoc = useMemo(
    () => profileSnap.data(),
    [profileSnap],
  );

  const { data: originSnap } = useFirestoreDoc(applicantDoc.originRef);

  if (!originSnap.exists()) {
    throw new Error('Origin not found');
  }

  const originDoc = useMemo(
    () => originSnap.data(),
    [originSnap],
  );

  const handleInstagramClick = useCallback(
    () => {
      if (applicantDoc.instagramTag) {
        window.Telegram.WebApp.openLink(`https://www.instagram.com/${applicantDoc.instagramTag}`);
      }
    },
    [applicantDoc.instagramTag],
  );

  const handleTikTokClick = useCallback(
    () => {
      if (applicantDoc.tiktokTag) {
        window.Telegram.WebApp.openLink(`https://www.tiktok.com/@${applicantDoc.tiktokTag}`);
      }
    },
    [applicantDoc.tiktokTag],
  );

  const handleLinkedInClick = useCallback(
    () => {
      if (applicantDoc.linkedinTag) {
        window.Telegram.WebApp.openLink(`https://www.linkedin.com/in/${applicantDoc.linkedinTag}`);
      }
    },
    [applicantDoc.linkedinTag],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      className="tripInfo"
    >
      <Container
        h="100%"
        maxW="lg"
        pb="calc(max(env(safe-area-inset-bottom), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
        pr={12}
        pt="calc(max(env(safe-area-inset-top), var(--chakra-space-4)) + var(--chakra-space-12) + var(--chakra-space-4))"
      >
        <Table size="sm" variant="simple">
          <Tbody>
            <Tr>
              <Td>
                Name
              </Td>
              <Td>
                {applicantDoc.name}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Age
              </Td>
              <Td>
                {applicantDoc.age}
                y
              </Td>
            </Tr>
            <Tr>
              <Td>
                Height
              </Td>
              <Td>
                {applicantDoc.height}
                cm
              </Td>
            </Tr>
            <Tr>
              <Td>
                Weight
              </Td>
              <Td>
                {applicantDoc.weight}
                kg
              </Td>
            </Tr>
            {applicantDoc.gender === Gender.FEMALE ? (
              <>
                <Tr>
                  <Td>
                    Bikini
                  </Td>
                  <Td>
                    {applicantDoc.cupSize ? cst(`${cupSizeLabels[applicantDoc.cupSize]}.label`) : '-'}
                    {' / '}
                    {applicantDoc.pantiesSize ? pst(`${pantiesSizeLabels[applicantDoc.pantiesSize]}.label`) : '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    Eyes
                  </Td>
                  <Td>
                    {applicantDoc.eyeColor ? ect(`${applicantDoc.eyeColor}.label`) : '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    Hair
                  </Td>
                  <Td>
                    {applicantDoc.hairColor ? hct(`${applicantDoc.hairColor}.label`) : '-'}
                    {' / '}
                    {applicantDoc.hairLength ? hlt(`${hairLengthLabels[applicantDoc.hairLength]}.label`) : '-'}
                  </Td>
                </Tr>
              </>
            ) : (
              <Tr>
                <Td>
                  Wealth
                </Td>
                <Td>
                  {applicantDoc.wealth ? wt(`${wealthLabels[applicantDoc.wealth]}.label`) : '-'}
                </Td>
              </Tr>
            )}
            <Tr>
              <Td>
                Zodiac
              </Td>
              <Td>
                {zt(applicantDoc.zodiac)}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Languages
              </Td>
              <Td whiteSpace="normal">
                {applicantDoc.languages.map((l) => lt(l)).join(', ')}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Relationship Style
              </Td>
              <Td>
                {(applicantDoc.relationshipStyles ?? []).map((r) => rst(`${r}.label`)).join(', ')}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Sexuality
              </Td>
              <Td>
                {applicantDoc.sexuality ? st(`${applicantDoc.sexuality}.label`) : '-'}
              </Td>
            </Tr>
            <Tr>
              <Td>
                Origin
              </Td>
              <Td whiteSpace="normal">
                {_.compact([
                  originDoc.name,
                  originDoc.cityName,
                  originDoc.countryName,
                ]).join(', ')}
              </Td>
            </Tr>
            {showPrivate ? (
              <>
                {applicantDoc.instagramTag ? (
                  <Tr>
                    <Td>
                      Instagram
                    </Td>
                    <Td>
                      <Button onClick={handleInstagramClick} variant="link">
                        {applicantDoc.instagramTag}
                      </Button>
                    </Td>
                  </Tr>
                ) : null}

                {applicantDoc.tiktokTag ? (
                  <Tr>
                    <Td>
                      TikTok
                    </Td>
                    <Td>
                      <Button onClick={handleTikTokClick} variant="link">
                        {applicantDoc.tiktokTag}
                      </Button>
                    </Td>
                  </Tr>
                ) : null}

                {applicantDoc.linkedinTag ? (
                  <Tr>
                    <Td>
                      LinkedIn
                    </Td>
                    <Td>
                      <Button onClick={handleLinkedInClick} variant="link">
                        {applicantDoc.linkedinTag}
                      </Button>
                    </Td>
                  </Tr>
                ) : null}
              </>
            ) : null}
          </Tbody>
        </Table>
      </Container>
    </Box>
  );
}
