/* eslint-disable react/require-default-props */
import { useEffect } from 'react';

export type Props = {
  color?: false | string;
  hasShineEffect?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  text: string;
  textColor?: false | string;
};

export function TelegramMainButton({
  color = false,
  hasShineEffect = false,
  isActive = true,
  isLoading = false,
  onClick,
  text,
  textColor = false,
}: Props) {
  useEffect(
    () => {
      window.Telegram.WebApp.MainButton.onClick(onClick);

      return () => {
        window.Telegram.WebApp.MainButton.offClick(onClick);
      };
    },
    [onClick],
  );

  useEffect(
    () => {
      window.Telegram.WebApp.MainButton.setParams({
        color,
        has_shine_effect: hasShineEffect,
        text,
        text_color: textColor,
      });
    },
    [color, hasShineEffect, text, textColor],
  );

  useEffect(
    () => {
      if (isLoading) {
        window.Telegram.WebApp.MainButton.showProgress();
      } else {
        window.Telegram.WebApp.MainButton.hideProgress();
      }
    },
    [isLoading],
  );

  useEffect(
    () => {
      if (isActive) {
        window.Telegram.WebApp.MainButton.enable();
      } else {
        window.Telegram.WebApp.MainButton.disable();
      }
    },
    [isActive],
  );

  useEffect(
    () => {
      window.Telegram.WebApp.MainButton.show();

      return () => {
        window.Telegram.WebApp.MainButton.hide();
      };
    },
    [],
  );

  return null;
}
