import { AvatarProps, Icon } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';
import { useFirestoreDoc } from 'reactfire';

import { ProfileDoc } from '../common/collections/Profiles';
import Catch from './Catch';
import StoragePictureAvatar from './StoragePictureAvatar';

export type Props = {
  profileRef: DocumentReference<ProfileDoc>;
} & AvatarProps;

export function ProfileAvatarMain({ profileRef, ...avatarProps }: Props) {
  const { data: profileSnap } = useFirestoreDoc(profileRef);

  if (!profileSnap.exists()) {
    throw new Error('Participant does not exist');
  }

  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  return (
    <StoragePictureAvatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      picture={profileDoc.pictures[0]}
    />
  );
}

export default function ProfileAvatar(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ProfileAvatarMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
