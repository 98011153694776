import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { FaInstagram } from 'react-icons/fa';

import useShowError from '../hooks/useShowError';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  helperText?: string;
  label: string;
  name: string;
} & InputProps;

export default function InstagramFormControl({
  helperText, label, name, ...inputProps
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<string>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      helper.setValue(e.target.value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FaInstagram} />
        </InputLeftElement>
        <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          onChange={handleChange}
          value={input.value}
        />
      </InputGroup>

      {helperText ? (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      ) : null}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
