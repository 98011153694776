enum Zodiac {
  AQUARIUS = 'AQUARIUS',
  ARIES = 'ARIES',
  CANCER = 'CANCER',
  CAPRICORN = 'CAPRICORN',
  GEMINI = 'GEMINI',
  LEO = 'LEO',
  LIBRA = 'LIBRA',
  PISCES = 'PISCES',
  SAGITTARIUS = 'SAGITTARIUS',
  SCORPIO = 'SCORPIO',
  TAURUS = 'TAURUS',
  VIRGO = 'VIRGO',
}

export default Zodiac;
