import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';
import { ApiResponse } from 'unsplash-js/dist/helpers/response';

export type Data = {
  downloadLocation: string;
};

export type Result = ApiResponse<{
  url: string;
}>;

export default function useUnsplashPhotosTrackDownload() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'unsplash-photos-trackDownload'), [functions]);
}
