/* eslint-disable perfectionist/sort-enums */
enum PantiesSize {
  XXS = 0,
  XS = 1,
  S = 2,
  M = 3,
  L = 4,
  XL = 5,
  XXL = 6,
}

export const pantiesSizeLabels = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
];

export default PantiesSize;
