import { SearchClient } from 'algoliasearch';
import { createContext, PropsWithChildren, useContext } from 'react';

type Props = {
  searchClient: SearchClient;
};

const AlgoliaSearchClientContext = createContext<null | SearchClient>(null);

export const useAlgoliaSearchClient = () => {
  const ctx = useContext(AlgoliaSearchClientContext);

  if (!ctx) {
    throw new Error('AlgoliaSearchClientProvider');
  }

  return ctx;
};

export default function AlgoliaSearchClientProvider({
  children,
  searchClient: sdk,
}: PropsWithChildren<Props>) {
  return (
    <AlgoliaSearchClientContext.Provider value={sdk}>
      {children}
    </AlgoliaSearchClientContext.Provider>
  );
}
