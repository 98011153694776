import { Grid, useRadioGroup, UseRadioGroupProps } from '@chakra-ui/react';

import Gender from '../../common/Gender';
import FemaleCard from './FemaleCard';
import MaleCard from './MaleCard';

export interface Props {
  label: string;
  name: string;
}

export default function GenderFormControl(props: UseRadioGroupProps) {
  const { getRadioProps, getRootProps } = useRadioGroup(props);

  const group = getRootProps();

  const radioFemale = getRadioProps({ value: Gender.FEMALE });
  const radioMale = getRadioProps({ value: Gender.MALE });

  return (
    <Grid
      gap={4}
      gridTemplateColumns="repeat(2, 1fr)"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...group}
    >
      <FemaleCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioFemale}
      />

      <MaleCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioMale}
      />
    </Grid>
  );
}
