import IPData, { LookupResponse } from 'ipdata';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

const ipData = new IPData('5c754f349de6e66dd7a224eb719129af4513f155bd54324f1af9fd12', {
  max: 1000, // max size
  maxAge: 24 * 60 * 60 * 1000, // max age in ms (i.e. 24 hours)
});

const IPDataContext = createContext<LookupResponse | null>(null);

export const useIPData = () => {
  const ctx = useContext(IPDataContext);
  return ctx;
};

export default function IPDataProvider({
  children,
}: PropsWithChildren) {
  const [lookupResponse, setLookupResponse] = useState<LookupResponse | null>(
    () => {
      const data = localStorage.getItem('ipData');
      if (data === null) {
        return null;
      }

      return JSON.parse(data) as LookupResponse;
    },
  );

  useEffect(
    () => {
      if (!lookupResponse) {
        ipData.lookup()
          .then((info) => {
            setLookupResponse(info);
            localStorage.setItem('ipData', JSON.stringify(info));
          })
          .catch(() => { /* do nothing */ });
      }
    },
    [lookupResponse],
  );

  return (
    <IPDataContext.Provider value={lookupResponse}>
      {children}
    </IPDataContext.Provider>
  );
}
