import {
  Center,
  Container,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppLanguage from '../../common/AppLanguage';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import DestinationForm from './DestinationForm';
import InitForm from './InitForm';

export function DestinationCreateScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('DestinationCreateScreen');

  const [destinationTemplate, setDestinationTemplate] = useState<{
    countryId: string;
    countryName: Record<AppLanguage, string>;
    description: Record<AppLanguage, string>;
    labels: string[];
    name: Record<AppLanguage, string>;
  }>();

  const onComplete = useCallback(
    () => {
      setDestinationTemplate(undefined);
    },
    [],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        {!destinationTemplate ? (
          <InitForm onComplete={setDestinationTemplate} />
        ) : (
          <DestinationForm
            initialValues={{ ...destinationTemplate, picture: null }}
            onComplete={onComplete}
          />
        )}
      </Container>
    </VStack>
  );
}

export default function DestinationCreateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <DestinationCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
