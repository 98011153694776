import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import ActionScreen from './screens/ActionScreen';
import ApplicationScreen from './screens/ApplicationScreen';
import CheckMyProfileRefLayout from './screens/CheckMyProfileRefLayout';
import CheckMyProfileSnapLayout from './screens/CheckMyProfileSnapLayout';
import DestinationCreateScreen from './screens/DestinationCreateScreen';
import DestinationUpdateScreen from './screens/DestinationUpdateScreen';
import LoginScreen from './screens/LoginScreen';
import MyProfileScreen from './screens/MyProfileScreen';
import MyProfileUpdateScreen from './screens/MyProfileUpdateScreen';
import ProfileScreen from './screens/ProfileScreen';
import TelegramLoginScreen from './screens/TelegramLoginScreen';
import TripCreateInitScreen from './screens/TripCreateInitScreen';
import TripCreateScreen from './screens/TripCreateScreen';
import TripScreen from './screens/TripScreen';
import TripUpdateScreen from './screens/TripUpdateScreen';
import VentureScreen from './screens/VentureScreen';
import WelcomeScreen from './screens/WelcomeScreen';

export default function Navigation() {
  return (
    <>
      <Helmet defaultTitle="Qupidu - Exclusive Travel Community" titleTemplate="%s | Qupidu" />

      <BrowserRouter>
        <Routes>
          <Route element={<ActionScreen />} index />
          <Route element={<LoginScreen />} path="login" />
          <Route element={<TelegramLoginScreen />} path="telegram/login" />

          <Route element={<CheckMyProfileRefLayout fallback={<Navigate replace to="/login" />} telegramFallback={<Navigate replace to="/telegram/login" />} />}>
            <Route element={<WelcomeScreen />} path="welcome" />
            <Route element={<VentureScreen />} path="ventures/:ventureId" />
            <Route element={<TripScreen />} path="trips/:tripId" />
            <Route element={<ProfileScreen />} path="profiles/:profileId" />

            <Route element={<CheckMyProfileSnapLayout fallback={<Navigate replace to="/welcome" />} />}>
              <Route element={<ApplicationScreen />} path="applications/:applicationId" />

              <Route path="profile">
                <Route element={<MyProfileScreen />} index />
                <Route element={<MyProfileUpdateScreen />} path="update" />
              </Route>

              <Route path="trips/add">
                <Route element={<TripCreateInitScreen />} index />
                <Route element={<TripCreateScreen />} path=":destinationId" />
              </Route>

              <Route element={<TripUpdateScreen />} path="trips/:tripId/edit" />

              <Route path="destinations">
                <Route element={<DestinationCreateScreen />} path="add" />
                <Route element={<DestinationUpdateScreen />} path=":destinationId" />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
