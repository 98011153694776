import {
  AspectRatio,
  Avatar,
  AvatarGroup,
  Box,
  BoxProps,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  ListItem,
  Portal,
  Text,
  UnorderedList,
  useClipboard,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  getDoc,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  refEqual,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram } from 'react-icons/fa';
import {
  LuArmchair,
  LuCalendar,
  LuCheck,
  LuHeart,
  LuLink,
  LuMapPin,
  LuUser,
} from 'react-icons/lu';
import Markdown, { Components } from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'reactfire';
import remarkGfm from 'remark-gfm';

import AppLanguage from '../../../common/AppLanguage';
import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../../common/collections/Applications';
import { ProfileDoc, useProfilesCollectionRef } from '../../../common/collections/Profiles';
import { VentureDoc } from '../../../common/collections/Ventures';
import Catch from '../../../components/Catch';
import ParticipantList from '../../../components/ParticipantList';
import ProfileAvatar from '../../../components/ProfileAvatar';
import StoragePictureAvatar from '../../../components/StoragePictureAvatar';
import StoragePictureImage from '../../../components/StoragePictureImage';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import i18n from '../../../i18n';
import VentureCard from '../VentureCard';

/* eslint-disable react/jsx-props-no-spreading */
const components: Components = {
  a: ({ node, ...props }) => (<Link isExternal {...props} />),
  h1: ({ node, ...props }) => (<Heading as="h1" size="lg" {...props} />),
  h2: ({ node, ...props }) => (<Heading as="h2" size="md" {...props} />),
  h3: ({ node, ...props }) => (<Heading as="h3" size="sm" {...props} />),
  li: ({ node, ...props }) => (<ListItem {...props} />),
  p: ({ node, ...props }) => (<Text as="p" fontSize="sm" {...props} />),
  strong: ({ node, ...props }) => (<Text as="strong" fontSize="sm" {...props} />),
  ul: ({ node, ...props }) => (<UnorderedList {...props} />),
};
/* eslint-enable react/jsx-props-no-spreading */

export type Props = {
  ventureSnap: QueryDocumentSnapshot<VentureDoc>;
} & BoxProps;

function useApplicationStatus(
  ventureRef: DocumentReference<VentureDoc>,
  profileRef: DocumentReference<ProfileDoc> | undefined,
): boolean {
  const [
    currentVentureRef,
    setCurrentVentureRef,
  ] = useState<DocumentReference<VentureDoc>>(ventureRef);

  const [
    currentProfileRef,
    setCurrentProfileRef,
  ] = useState<DocumentReference<ProfileDoc> | undefined>(profileRef);

  useEffect(
    () => {
      if (!refEqual(ventureRef, currentVentureRef)) {
        setCurrentVentureRef(ventureRef);
      }
    },
    [currentVentureRef, ventureRef],
  );

  useEffect(
    () => {
      if (!profileRef) {
        setCurrentProfileRef(undefined);
      } else if (!currentProfileRef) {
        setCurrentProfileRef(profileRef);
      } else if (!refEqual(profileRef, currentProfileRef)) {
        setCurrentProfileRef(profileRef);
      }
    },
    [currentProfileRef, profileRef],
  );

  const [applicationExists, setApplicationExists] = useState<boolean>(false);

  const applicationsCollectionRef = useApplicationsCollectionRef();

  useEffect(
    () => {
      if (currentProfileRef) {
        return onSnapshot(
          query(
            applicationsCollectionRef,
            where('applicantRef', '==', currentProfileRef),
            where('subjectRef', '==', currentVentureRef),
          ),
          (snap) => {
            setApplicationExists(snap.docs.length > 0);
          },
        );
      }

      setApplicationExists(false);
      return () => { };
    },
    [applicationsCollectionRef, currentProfileRef, currentVentureRef],
  );

  return applicationExists;
}

export function VentureRowMain({
  ventureSnap, ...boxProps
}: Props) {
  const { t } = useTranslation('TheirVenturesScreen', { keyPrefix: 'Venture' });

  const { data: user } = useUser({ suspense: true });
  const profilesCollectionRef = useProfilesCollectionRef();
  const myProfileRef = useMemo(
    () => (user?.uid ? doc(profilesCollectionRef, user.uid) : undefined),
    [profilesCollectionRef, user?.uid],
  );

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const navigate = useNavigate();
  const onSwipeRight = useCallback(
    async () => {
      if (!myProfileRef) {
        navigate('/login');
        return;
      }

      const myProfileSnap = await getDoc(myProfileRef);

      if (!myProfileSnap.exists()) {
        navigate('/welcome');
        return;
      }

      const applicationRef = doc(applicationsCollectionRef);
      const ventureDoc = ventureSnap.data();

      await setDoc(
        applicationRef,
        {
          _v: 1,
          applicantRef: myProfileRef,
          organizerRef: ventureDoc.organizerRef,
          sentAt: Timestamp.now(),
          status: ApplicationStatus.SENT,
          subjectRef: ventureSnap.ref,
        },
      );
    },
    [applicationsCollectionRef, myProfileRef, navigate, ventureSnap],
  );

  const applicationExists = useApplicationStatus(ventureSnap.ref, myProfileRef);

  const handleLikeClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Venture Like Clicked');

      onSwipeRight().catch(() => { });
    },
    [onSwipeRight],
  );

  const ventureDoc = useMemo(() => ventureSnap.data(), [ventureSnap]);

  const { snap: citySnap } = useDocumentSnapshot(ventureDoc.cityRef);
  const cityDoc = useMemo(() => citySnap?.data(), [citySnap]);

  const {
    isOpen: isVentureCardOpen,
    onClose: onVentureCardClose,
    onOpen: onVentureCardOpen,
  } = useDisclosure();

  const {
    isOpen: isFemaleParticipantCardsOpen,
    onClose: onFemaleParticipantCardsClose,
    onOpen: onFemaleParticipantCardsOpen,
  } = useDisclosure();

  const {
    isOpen: isMaleParticipantCardsOpen,
    onClose: onMaleParticipantCardsClose,
    onOpen: onMaleParticipantCardsOpen,
  } = useDisclosure();

  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const { snap: organizerSnap } = useDocumentSnapshot(ventureDoc.organizerRef);
  const organizerDoc = useMemo(() => organizerSnap?.data(), [organizerSnap]);

  const { hasCopied, onCopy } = useClipboard(`https://qupidu.com/ventures/${ventureSnap.id}`);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <VStack
        alignItems="stretch"
        gap={2}
        h="100%"
        userSelect="none"
      >
        <Grid
          autoRows="1fr"
          gap={2}
          templateColumns="repeat(3, 1fr)"
        >
          {ventureDoc.pictures.map((picture) => (
            <GridItem key={picture.storageRef}>
              <AspectRatio ratio={9 / 16}>
                <StoragePictureImage
                  borderRadius="md"
                  cursor="pointer"
                  h="100%"
                  objectFit="cover"
                  onClick={onVentureCardOpen}
                  picture={picture}
                />
              </AspectRatio>
            </GridItem>
          ))}

          {new Array(Math.ceil(ventureDoc.pictures.length / 3) * 3 - ventureDoc.pictures.length)
            .fill(null)
            .map((_, i) => (
              <Box
                _dark={{
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                }}
                _light={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                }}
                borderRadius="md"
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              />
            ))}
        </Grid>

        <HStack>
          <Text
            flex={1}
            fontSize="xl"
            fontWeight="bold"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {ventureDoc.name}
          </Text>

          {ventureDoc.instagramTag ? (
            <IconButton
              aria-label="Instagram"
              as="a"
              href={`https://www.instagram.com/${ventureDoc.instagramTag}`}
              icon={<Icon as={FaInstagram} />}
              size="sm"
              target="_blank"
            />
          ) : null}
        </HStack>

        <Grid gap={2} templateColumns="repeat(2, 1fr)">
          <GridItem
            cursor={(ventureDoc.femaleParticipants.length > 0) ? 'pointer' : undefined}
            onClick={
              (ventureDoc.femaleParticipants.length > 0)
                ? onFemaleParticipantCardsOpen
                : undefined
            }
          >
            <VStack
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              alignItems="stretch"
              borderRadius="md"
              h="100%"
              p={2}
            >
              <Grid gap={1} templateColumns="repeat(3, auto)">
                <Text
                  fontSize="xs"
                  justifySelf="start"
                  opacity={0.75}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {t('InfoCell.femaleParticipants.label')}
                </Text>

                <Text
                  fontSize="xs"
                  justifySelf="center"
                  opacity={0.75}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {t('InfoCell.femalePrice.value', {
                    amount: ventureDoc.femaleParticipantPrice.amount,
                    formatParams: {
                      amount: { currency: ventureDoc.femaleParticipantPrice.currency },
                    },
                  })}
                </Text>

                <Text
                  fontSize="xs"
                  justifySelf="end"
                  opacity={0.75}
                >
                  {ventureDoc.femaleParticipants.length}
                  /
                  {ventureDoc.femaleParticipantPlaces}
                </Text>
              </Grid>

              <AvatarGroup max={6} size="sm">
                {ventureDoc.femaleParticipants.map((participantRef) => (
                  <ProfileAvatar key={participantRef.id} profileRef={participantRef} />
                ))}

                {new Array(
                  ventureDoc.femaleParticipantPlaces - ventureDoc.femaleParticipants.length,
                )
                  .fill(null)
                  .map((v, i) => (
                    <Avatar
                      bg="purple.500"
                      color="white"
                      icon={<Icon as={LuArmchair} />}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                    />
                  ))}
              </AvatarGroup>
            </VStack>
          </GridItem>

          <GridItem
            cursor={(ventureDoc.maleParticipants.length > 0) ? 'pointer' : undefined}
            onClick={
              (ventureDoc.maleParticipants.length > 0)
                ? onMaleParticipantCardsOpen
                : undefined
            }
          >
            <VStack
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              alignItems="stretch"
              borderRadius="md"
              h="100%"
              p={2}
            >
              <Grid gap={1} templateColumns="repeat(3, auto)">
                <Text
                  fontSize="xs"
                  justifySelf="start"
                  opacity={0.75}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {t('InfoCell.maleParticipants.label')}
                </Text>

                <Text
                  fontSize="xs"
                  justifySelf="center"
                  opacity={0.75}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {t('InfoCell.malePrice.value', {
                    amount: ventureDoc.maleParticipantPrice.amount,
                    formatParams: {
                      amount: { currency: ventureDoc.maleParticipantPrice.currency },
                    },
                  })}
                </Text>

                <Text
                  fontSize="xs"
                  justifySelf="end"
                  opacity={0.75}
                >
                  {ventureDoc.maleParticipants.length}
                  /
                  {ventureDoc.maleParticipantPlaces}
                </Text>
              </Grid>

              <AvatarGroup max={6} size="sm">
                {ventureDoc.maleParticipants.map((participantRef) => (
                  <ProfileAvatar key={participantRef.id} profileRef={participantRef} />
                ))}

                {new Array(ventureDoc.maleParticipantPlaces - ventureDoc.maleParticipants.length)
                  .fill(null)
                  .map((v, i) => (
                    <Avatar
                      bg="purple.500"
                      color="white"
                      icon={<Icon as={LuArmchair} />}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                    />
                  ))}
              </AvatarGroup>
            </VStack>
          </GridItem>
        </Grid>

        <Markdown components={components} remarkPlugins={[remarkGfm]}>
          {/* eslint-disable-next-line max-len */}
          {ventureDoc.translations?.description[i18n.language as AppLanguage] ?? ventureDoc.description}
        </Markdown>

        {organizerDoc ? (
          <VStack alignItems="stretch" spacing={1}>
            <Text
              fontSize="xs"
              opacity={0.75}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('InfoCell.organizer.label')}
            </Text>

            <HStack>
              <StoragePictureAvatar
                icon={<Icon as={LuUser} />}
                loading="lazy"
                picture={organizerDoc.pictures[0]}
                size="xs"
              />

              <Text
                fontSize="sm"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {organizerDoc.name}
              </Text>
            </HStack>
          </VStack>
        ) : null}

        <Grid gap={1} templateColumns="repeat(2, auto)">
          <HStack gap={1} justifySelf="start" maxW="100%" minW={0} opacity={0.75}>
            <Icon as={LuMapPin} boxSize={3} />
            <Text
              fontSize="sm"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {compact([cityDoc?.name, cityDoc?.countryName]).join(', ')}
            </Text>
          </HStack>

          <HStack gap={1} justifySelf="end" opacity={0.75}>
            <Icon as={LuCalendar} boxSize={3} />
            <Text
              fontSize="sm"
              whiteSpace="nowrap"
            >
              {t('InfoCell.startsAt.value', {
                formatParams: { startsAt: { dateStyle: 'short', timeStyle: 'short' } },
                startsAt: ventureDoc.startsAt.toDate(),
              })}
            </Text>
          </HStack>
        </Grid>

        <HStack>
          <IconButton
            aria-label={t('copyButton.default')}
            icon={<Icon as={hasCopied ? LuCheck : LuLink} />}
            onClick={onCopy}
          />

          <Button
            colorScheme="purple"
            flex={1}
            isDisabled={applicationExists}
            leftIcon={<Icon as={LuHeart} />}
            onClick={handleLikeClick}
          >
            {t('applyButton.default')}
          </Button>
        </HStack>

        <Portal>
          {isVentureCardOpen ? (
            <VentureCard
              height={wHeight}
              onExit={onVentureCardClose}
              position="absolute"
              ventureSnap={ventureSnap}
              width={wWidth}
              zIndex={100}
            />
          ) : null}

          {isFemaleParticipantCardsOpen && (ventureDoc.femaleParticipants.length > 0) ? (
            <ParticipantList
              height={wHeight}
              onExit={onFemaleParticipantCardsClose}
              participantRefs={ventureDoc.femaleParticipants}
              width={wWidth}
            />
          ) : null}

          {isMaleParticipantCardsOpen && (ventureDoc.maleParticipants.length > 0) ? (
            <ParticipantList
              height={wHeight}
              onExit={onMaleParticipantCardsClose}
              participantRefs={ventureDoc.maleParticipants}
              width={wWidth}
            />
          ) : null}
        </Portal>
      </VStack>
    </Box>
  );
}

export function VentureRowLoading({ ...boxProps }: BoxProps) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      Loading...
    </Box>
  );
}

export default function VentureRow({ ventureSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <VentureRowLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <VentureRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          ventureSnap={ventureSnap}
        />
      </Suspense>
    </Catch>
  );
}
