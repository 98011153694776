import { insightsClient } from '@algolia/client-insights';
import { createContext, PropsWithChildren, useContext } from 'react';

type Props = {
  insightsClient: ReturnType<typeof insightsClient>;
};

const AlgoliaInsightsClientContext = createContext<null | ReturnType<typeof insightsClient>>(null);

export const useAlgoliaInsightsClient = () => {
  const ctx = useContext(AlgoliaInsightsClientContext);

  if (!ctx) {
    throw new Error('AlgoliaInsightsClientProvider');
  }

  return ctx;
};

export default function AlgoliaInsightsClientProvider({
  children,
  insightsClient: sdk,
}: PropsWithChildren<Props>) {
  return (
    <AlgoliaInsightsClientContext.Provider value={sdk}>
      {children}
    </AlgoliaInsightsClientContext.Provider>
  );
}
