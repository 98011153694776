import { Box, BoxProps } from '@chakra-ui/react';

import { StoragePicture } from '../common/StoragePicture';
import StoragePictureImage from './StoragePictureImage';

export type Props = {
  height: number;
  picture: StoragePicture;
  width: number;
} & BoxProps;

export default function StoragePictureCell({
  height,
  picture,
  width,
  ...boxProps
}: Props) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <StoragePictureImage
        alt=""
        h={`${height}px`}
        objectFit="cover"
        picture={picture}
        scrollSnapAlign="start"
        scrollSnapStop="always"
        w={`${width}px`}
      />
    </Box>
  );
}
