/* eslint-disable react/require-default-props */
import { Center } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense } from 'react';
import { useFirestoreDoc } from 'reactfire';

import { ProfileDoc } from '../../common/collections/Profiles';
import ErrorFallbackScreen from '../../screens/ErrorFallbackScreen';
import Catch from '../Catch';
import LogoIcon from '../LogoIcon';
import ProfileCard, { Props as ProfileCardProps } from '../ProfileCard';

export type Props = {
  onExit?: () => void;
  participantRef: DocumentReference<ProfileDoc>;
} & Omit<ProfileCardProps, 'profileSnap'>;

export function ParticipantItemMain({ onExit, participantRef, ...profileCardProps }: Props) {
  const { data: participantSnap } = useFirestoreDoc(participantRef);

  if (!participantSnap.exists()) {
    throw new Error('Participant not found');
  }

  return (
    <ProfileCard
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...profileCardProps}
      onExit={onExit}
      profileSnap={participantSnap}
    />
  );
}

export default function ParticipantItem(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ParticipantItemMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
