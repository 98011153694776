/* eslint-disable perfectionist/sort-enums */
enum HairLength {
  BUZZ_CUT = 0,
  EAR = 1,
  CHIN = 2,
  SHOULDER = 3,
  ARMPIT = 4,
  MID_BACK = 5,
  TAILBONE = 6,
}

export const hairLengthLabels = [
  'BUZZ_CUT',
  'EAR',
  'CHIN',
  'SHOULDER',
  'ARMPIT',
  'MID_BACK',
  'TAILBONE',
];

export default HairLength;
