import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export interface Props {
  label: string;
  max: number;
  min: number;
  name: string;
  // eslint-disable-next-line react/require-default-props
  right?: string;
  step: number;
}

export default function NumberFormControl({
  label, max, min, name, right = undefined,
  step,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<number>(name);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      helper.setValue(parseFloat(e.target.value))
        .catch(showError);
    },
    [helper, showError],
  );

  const handleSliderChange = useCallback(
    (value: number) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <VStack alignItems="stretch" spacing={4}>
        <Slider
          focusThumbOnChange={false}
          max={max}
          min={min}
          onChange={handleSliderChange}
          step={step}
          value={input.value}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <SliderThumb />
        </Slider>

        <InputGroup>
          <Input onChange={handleInputChange} type="number" value={input.value} />
          {right ? (
            <InputRightAddon>{right}</InputRightAddon>
          ) : null}
        </InputGroup>
      </VStack>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
