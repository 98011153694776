enum HairColor {
  BLACK = 'BLACK',
  BLONDE = 'BLONDE',
  BROWN = 'BROWN',
  CHESTNUT = 'CHESTNUT',
  GRAY = 'GRAY',
  OTHER = 'OTHER',
  RED = 'RED',
  WHITE = 'WHITE',
}

export default HairColor;
