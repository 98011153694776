import { AspectRatio, Skeleton } from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useCallback, useMemo } from 'react';

import { ProfileDoc } from '../../../common/collections/Profiles';
import WebAppAction, { WebAppActionType } from '../../../common/WebAppAction';
import StoragePictureImage from '../../../components/StoragePictureImage';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';

function base64URLencode(str: string) {
  const base64Encoded = btoa(str);
  return base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export type ParticipantCardMainProps = {
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
};

export function ParticipantCardMain({
  profileSnap,
}: ParticipantCardMainProps) {
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const handleClick = useCallback(
    () => {
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/qupidubot?startapp=${base64URLencode(JSON.stringify({ action: WebAppActionType.VIEW_PROFILE, profileId: profileSnap.id } as WebAppAction))}`,
      );
    },
    [profileSnap.id],
  );

  return (
    <AspectRatio ratio={9 / 16}>
      <StoragePictureImage
        borderRadius="md"
        cursor="pointer"
        h="100%"
        objectFit="cover"
        onClick={handleClick}
        picture={profileDoc.pictures[0]}
      />
    </AspectRatio>
  );
}

export type ParticipantCardProps = {
  profileRef: DocumentReference<ProfileDoc>;
};

export function ParticipantCardLoading() {
  return (
    <AspectRatio ratio={9 / 16}>
      <Skeleton h="100%" />
    </AspectRatio>
  );
}

export default function ParticipantCard({ profileRef }: ParticipantCardProps) {
  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  if (!profileSnap) {
    return (
      <ParticipantCardLoading />
    );
  }

  if (!profileSnap.exists()) {
    return (
      <ParticipantCardLoading />
    );
  }

  return (
    <Suspense fallback={<ParticipantCardLoading />}>
      <ParticipantCardMain profileSnap={profileSnap} />
    </Suspense>
  );
}
