/* eslint-disable react/require-default-props */
import { Box, Center } from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { DocumentReference } from 'firebase/firestore';
import { clamp } from 'lodash';
import { Suspense, useRef, useState } from 'react';

import { ProfileDoc } from '../../common/collections/Profiles';
import ErrorFallbackScreen from '../../screens/ErrorFallbackScreen';
import Catch from '../Catch';
import LogoIcon from '../LogoIcon';
import ParticipantItem from './ParticipantItem';

export type Props = {
  height: number;
  onExit?: () => void;
  participantRefs: DocumentReference<ProfileDoc>[];
  width: number;
};

export function ParticipantListMain({
  height, onExit, participantRefs, width,
}: Props) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(participantRefs.length, (i) => ({
    borderRadius: 0,
    display: 'block',
    scale: 1,
    x: i * width,
  }));

  const bind = useDrag(({
    active, cancel, direction: [xDir], movement: [mx],
  }) => {
    if (active && Math.abs(mx) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (xDir > 0 ? -1 : 1),
        0,
        participantRefs.length - 1,
      );
      setCurrentIndex(animationIndex.current);
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          display: 'none',
        };
      }

      const borderRadius = active
        ? (clamp(
          Math.abs(mx),
          0,
          100,
        ) / 100) * 32
        : 0;
      const x = (i - animationIndex.current) * width + (active ? mx : 0);
      const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
      return {
        borderRadius, display: 'block', scale, x,
      };
    });
  }, {
    axis: 'x',
    preventDefault: true,
    threshold: 10,
  });

  if (!participantRefs.length) {
    return null;
  }

  return (
    <>
      {pages.map(({
        borderRadius, display, scale, x,
      }, searchResultPosition) => (
        Math.abs(currentIndex - searchResultPosition) > 5
          ? null
          : (
            <Box
              as={animated.div}
              h={`${height}px`}
              key={participantRefs[searchResultPosition].id}
              left={0}
              position="absolute"
              top={0}
              w={`${width}px`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bind()}
              style={{
                display,
                touchAction: 'none',
                x,
              }}
            >
              <ParticipantItem
                as={animated.div}
                height={height}
                onExit={onExit}
                overflow="hidden"
                participantRef={participantRefs[searchResultPosition]}
                style={{
                  borderRadius: borderRadius as unknown as number,
                  scale: scale as unknown as number,
                  touchAction: 'none',
                }}
                width={width}
              />
            </Box>
          )
      ))}
    </>
  );
}

export default function ParticipantList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ParticipantListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
