import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  GeoPoint,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { type CityDoc } from './Cities';
import { type ContinentDoc } from './Continent';
import { type CountryDoc } from './Countries';
import { type CurrencyDoc } from './Currency';

export interface AirportDoc {
  _v: 1;
  cityName?: string;
  cityRef?: DocumentReference<CityDoc>;
  continentName?: string;
  continentRef?: DocumentReference<ContinentDoc>;
  countryEmoji?: string;
  countryName?: string;
  countryRef?: DocumentReference<CountryDoc>;
  currencyName?: string;
  currencyRef?: DocumentReference<CurrencyDoc>;
  iataCode: string;
  icaoCode?: string;
  location?: GeoPoint;
  name?: string;
  phoneNumber?: string;
  timezone?: string;
  updatedAt: Timestamp;
}

export const isAirportDoc = (
  data?: DocumentData,
): data is AirportDoc => true;

export const isAirportRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AirportDoc> => ref.parent.id === 'airports';

function isAirportSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<AirportDoc>;
function isAirportSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<AirportDoc>;
function isAirportSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<AirportDoc> | QueryDocumentSnapshot<AirportDoc> {
  return isAirportRef(snap.ref);
}

export { isAirportSnap };

export const useAirportsCollectionRef = () => {
  const firestore = useFirestore();
  return collection(firestore, 'airports') as CollectionReference<AirportDoc>;
};
