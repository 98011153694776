import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CupSize from '../common/CupSize';
import useShowError from '../hooks/useShowError';
import Catch from './Catch';

export interface Props {
  label: string;
  name: string;
}

export function CupSizeFormControlMain({
  label, name,
}: Props) {
  const { t } = useTranslation('CupSize');
  const showError = useShowError();

  const [input, meta, helper] = useField<number>(name);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(parseFloat(e.target.value))
        .catch(showError);
    },
    [helper, showError],
  );

  const handleSliderChange = useCallback(
    (value: number) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <VStack alignItems="stretch" spacing={4}>
        <Slider
          focusThumbOnChange={false}
          max={CupSize.E}
          min={CupSize.AA}
          onChange={handleSliderChange}
          step={1}
          value={input.value}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <SliderThumb />
        </Slider>

        <Select onChange={handleInputChange} value={input.value}>
          <option value={CupSize.AA}>{t('AA.label')}</option>
          <option value={CupSize.A}>{t('A.label')}</option>
          <option value={CupSize.B}>{t('B.label')}</option>
          <option value={CupSize.C}>{t('C.label')}</option>
          <option value={CupSize.D}>{t('D.label')}</option>
          <option value={CupSize.DD}>{t('DD.label')}</option>
          <option value={CupSize.E}>{t('E.label')}</option>
        </Select>
      </VStack>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default function CupSizeFormControl(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <CupSizeFormControlMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
