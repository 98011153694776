import { useCallback, useLayoutEffect, useState } from 'react';

export default function useDimensions() {
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();

  const [node, setNode] = useState<HTMLElement | null>(null);

  const ref = useCallback((n: HTMLElement | null) => { setNode(n); }, []);

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (node) {
      const measure = () => window.requestAnimationFrame(
        () => {
          const dimensions = node.getBoundingClientRect();
          setHeight(Math.round(dimensions.height));
          setWidth(Math.round(dimensions.width));
        },
      );
      measure();

      window.addEventListener('resize', measure);
      window.addEventListener('scroll', measure);

      return () => {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      };
    }
  }, [node]);

  return [ref, { height, width }] as const;
}
