import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useVenturesCollectionRef } from '../../common/collections/Ventures';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VentureRow from '../TheirVenturesScreen/VentureRow';

export function TheirVenturesScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  useEffect(() => { mixpanel.track_pageview({ screen: 'TheirVenturesScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('TheirVenturesScreen');

  const { ventureId } = useParams<{ ventureId: string }>();

  const venturesCollectionRef = useVenturesCollectionRef();
  const { data: ventureSnap } = useFirestoreDoc(
    doc(
      venturesCollectionRef,
      ventureId,
    ),
  );

  if (!ventureSnap.exists()) {
    throw new Error('Venture not found');
  }

  const ventureDoc = useMemo(() => ventureSnap.data(), [ventureSnap]);

  return (
    <>
      <Helmet>
        <title>{ventureDoc.name}</title>
      </Helmet>

      <VStack alignItems="stretch" gap={0} h="100%">
        <Container
          maxW="lg"
          pb={2}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          <HStack gap={4}>
            {window.Telegram.WebApp.initData === '' && (
              <IconButton
                aria-label={t('backButton.default')}
                as={Link}
                icon={<Icon as={LuChevronLeft} />}
                to=".."
              />
            )}

            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {ventureDoc.name}
            </Heading>
          </HStack>
        </Container>

        <Container
          flex={1}
          height="100%"
          maxW="lg"
          overflow="auto"
          style={{
            paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
          }}
        >
          <VentureRow
            ventureSnap={ventureSnap}
          />
        </Container>
      </VStack>
    </>
  );
}

export default function TheirVenturesScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <TheirVenturesScreenMain />
      </Suspense>
    </Catch>
  );
}
