/* eslint-disable perfectionist/sort-enums */
enum CupSize {
  AA = 0,
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  DD = 5,
  E = 6,
}

export const cupSizeLabels = [
  'AA',
  'A',
  'B',
  'C',
  'D',
  'DD',
  'E',
];

export default CupSize;
