import { Center } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useUser } from 'reactfire';

import { useProfilesCollectionRef } from '../common/collections/Profiles';
import Catch from '../components/Catch';
import LogoIcon from '../components/LogoIcon';
import MyProfileRefProvider from '../components/refProviders/MyProfileRefProvider';
import ErrorFallbackScreen from './ErrorFallbackScreen';

export type Props = {
  fallback: React.ReactElement;
  telegramFallback: React.ReactElement;
};

export function CheckMyProfileRefLayoutMain({ fallback, telegramFallback }: Props) {
  const { data: user } = useUser({ suspense: true });

  const profilesCollectionRef = useProfilesCollectionRef();

  const myProfileRef = useMemo(
    () => (user?.uid ? doc(profilesCollectionRef, user.uid) : undefined),
    [profilesCollectionRef, user?.uid],
  );

  useEffect(
    () => {
      if (myProfileRef?.id) {
        mixpanel.identify(myProfileRef.id);
      }
    },
    [myProfileRef?.id],
  );

  if (!myProfileRef) {
    if (Telegram.WebApp.initData !== '') {
      return telegramFallback;
    }

    return fallback;
  }

  return (
    <MyProfileRefProvider
      profileRef={myProfileRef}
    >
      <Outlet />
    </MyProfileRefProvider>
  );
}

export default function CheckMyProfileRefLayout(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <CheckMyProfileRefLayoutMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
