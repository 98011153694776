import { Container, HStack, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ProfileVersionDoc } from '../../common/collections/Profiles';
import Gender from '../../common/Gender';
import Wealth from '../../common/Wealth';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import { TelegramMainButton } from '../../components/TelegramMainButton';
import WealthFormControl from '../../components/WealthFormControl';

export type Props = {
  data: Pick<ProfileVersionDoc, 'wealth'> | undefined;
  onComplete: (data: Pick<ProfileVersionDoc, 'wealth'>) => void;
};

export function WealthFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      wealth: yup
        .number()
        .label(t('wealth.label'))
        .integer()
        .min(Wealth.BROKE)
        .max(Wealth.MILLIONAIRE)
        .when('gender', {
          is: Gender.MALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      wealth: data?.wealth ?? Wealth.BROKE,
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={onComplete}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <WealthFormControl
                  helperText={t('wealth.helperText')}
                  label={t('wealth.label')}
                  name="wealth"
                />
              </VStack>
            </Container>

            <TelegramMainButton
              color={isValid ? '#424242' : '#F44336'}
              isLoading={isValidating || isSubmitting}
              onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                setValidateAll(true);
                handleSubmit();
              }}
              text={isValid ? t('nextButton.default') : t('nextButton.invalid')}
              textColor="#FFFFFF"
            />
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function WealthForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <WealthFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
