import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../AppLanguage';
import CupSize from '../CupSize';
import EyeColor from '../EyeColor';
import Gender from '../Gender';
import HairColor from '../HairColor';
import HairLength from '../HairLength';
import Language from '../Language';
import PantiesSize from '../PantiesSize';
import RelationshipStyle from '../RelationshipStyle';
import Sexuality from '../Sexuality';
import { StoragePicture } from '../StoragePicture';
import Tier from '../Tier';
import Wealth from '../Wealth';
import Zodiac from '../Zodiac';
import { type AirportDoc } from './Airports';
import { type TripDoc } from './Trips';
import { VendorDoc } from './Vendors';

export enum Goal {
  FRIENDSHIP = 'FRIENDSHIP',
  FUN = 'FUN',
  LOVE = 'LOVE',
  MARRIAGE = 'MARRIAGE',
  MODELLING = 'MODELLING',
  PARTYING = 'PARTYING',
  SEX = 'SEX',
  TRAVEL = 'TRAVEL',
}

export interface ProfileDoc {
  _v: 1;
  age: number;
  bannedAt?: Timestamp;
  createdAt: Timestamp;
  cupSize?: CupSize;
  description?: string;
  expiresAt: Timestamp;
  eyeColor?: EyeColor;
  gender: Gender;
  goals?: Goal[];
  h3Cell1?: string;
  h3Cell2?: string;
  h3Cell3?: string;
  h3Cell4?: string;
  h3Cell5?: string;
  h3Cell6?: string;
  h3Cell7?: string;
  hairColor?: HairColor;
  hairLength?: HairLength;
  height?: number;
  indexedAt?: Timestamp;
  instagramTag?: string;
  isBanned?: boolean;
  languages: Language[];
  lastNotificationAt: Timestamp;
  linkedinTag?: string;
  name: string;
  originRef: DocumentReference<AirportDoc>;
  pantiesSize?: PantiesSize;
  pictures: [StoragePicture, ...StoragePicture[]];
  preferences?: {
    age?: {
      max: number;
      min: number;
    };
    height?: {
      max: number;
      min: number;
    };
    tier?: {
      max: Tier;
      min: Tier;
    };
    weight?: {
      max: number;
      min: number;
    };
  };
  relationshipStyles?: RelationshipStyle[];
  score: number;
  scoredAt: Timestamp;
  sexuality: Sexuality;
  stripeCustomerId?: string;
  stripePromotionCode?: string;
  tiktokTag?: string;
  translations?: {
    name: Record<AppLanguage, string>;
  };
  updatedAt: Timestamp;
  vendorRef?: DocumentReference<VendorDoc>;
  wealth?: Wealth;
  weight?: number;
  zodiac: Zodiac;
}

export const isProfileDoc = (
  data?: DocumentData,
): data is ProfileDoc => true;

export const isProfileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileDoc> => ref.parent.id === 'profiles';

function isProfileSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileDoc>;
function isProfileSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileDoc>;
function isProfileSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileDoc> | QueryDocumentSnapshot<ProfileDoc> {
  return isProfileRef(snap.ref);
}

export { isProfileSnap };

export const getProfilesCollectionRef = (firestore: Firestore) => collection(firestore, 'profiles') as CollectionReference<ProfileDoc>;

export const useProfilesCollectionRef = () => {
  const firestore = useFirestore();
  return getProfilesCollectionRef(firestore);
};

export interface ProfileHiddenDoc {
  _v: 1;
  appLanguage?: AppLanguage;
  birthDate?: string;
  fcmTokens?: string[];
  profileDeck?: DocumentReference<ProfileDoc>[];
  profileSuggestions?: string[];
  timezone?: string;
  tripDeck?: DocumentReference<TripDoc>[];
}

export const isProfileHiddenDoc = (
  data?: DocumentData,
): data is ProfileHiddenDoc => true;

export const isProfileHiddenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileHiddenDoc> => ref.id === 'internal' && ref.parent.id === 'hidden' && ref.parent.parent !== null && isProfileRef(ref.parent.parent);

function isProfileHiddenSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileHiddenDoc>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileHiddenDoc>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileHiddenDoc> | QueryDocumentSnapshot<ProfileHiddenDoc> {
  return isProfileHiddenRef(snap.ref);
}

export { isProfileHiddenSnap };

export const getProfileHiddenRef = (profileRef: DocumentReference<ProfileDoc>) => doc(collection(profileRef, 'internal'), 'hidden') as DocumentReference<ProfileHiddenDoc>;

export enum ProfileVersionStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  WITHDRAWN = 'WITHDRAWN',
}

export enum ProfileVersionRejectionReason {
  INSTAGRAM_VERIFICATION = 'INSTAGRAM_VERIFICATION',
  NO_BIKINI_PICTURES = 'NO_BIKINI_PICTURES',
  PICTURES_CONTENT = 'PICTURES_CONTENT',
  PICTURES_QUALITY = 'PICTURES_QUALITY',
}

export type ProfileVersionDoc = {
  _v: 1;
  approvedAt?: Timestamp;
  instagramTag: string;
  rejectedAt?: Timestamp;
  rejectionReason?: ProfileVersionRejectionReason;
  sentAt: Timestamp;
  status: ProfileVersionStatus;
  withdrawnAt?: Timestamp;
} & Pick<ProfileDoc, 'cupSize' | 'description' | 'eyeColor' | 'gender' | 'goals' | 'hairColor' | 'hairLength' | 'height' | 'languages' | 'linkedinTag' | 'name' | 'originRef' | 'pantiesSize' | 'pictures' | 'relationshipStyles' | 'sexuality' | 'tiktokTag' | 'wealth' | 'weight'> & Pick<ProfileHiddenDoc, 'birthDate'>;

export const isProfileVersionDoc = (
  data?: DocumentData,
): data is ProfileVersionDoc => true;

export const isProfileVersionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileVersionDoc> => ref.parent.id === 'profileVersions' && ref.parent.parent !== null && isProfileRef(ref.parent.parent);

function isProfileVersionSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileVersionDoc>;
function isProfileVersionSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileVersionDoc>;
function isProfileVersionSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileVersionDoc> | QueryDocumentSnapshot<ProfileVersionDoc> {
  return isProfileVersionRef(snap.ref);
}

export { isProfileVersionSnap };

export const getProfileVersionsCollectionRef = (profileRef: DocumentReference<ProfileDoc>) => collection(profileRef, 'profileVersions') as CollectionReference<ProfileVersionDoc>;
