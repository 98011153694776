import { Container, HStack, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ProfileVersionDoc } from '../../common/collections/Profiles';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import { TelegramMainButton } from '../../components/TelegramMainButton';
import TextFormControl from '../../components/TextFormControl';

export type Props = {
  data: Pick<ProfileVersionDoc, 'name'> | undefined;
  onComplete: (data: Pick<ProfileVersionDoc, 'name'>) => void;
};

export function NameFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  // const validateProfileName = useValidateProfileName();

  // const nameTest = useCallback(async (
  //   value: string,
  //   context: yup.TestContext,
  // ) => {
  //   const cache = localStorage.getItem(`validateProfileName::${JSON.stringify(value)}`);

  //   let validationResult: Record<string, boolean>;

  //   try {
  //     if (cache) {
  //       validationResult = JSON.parse(cache) as Record<string, boolean>;
  //     } else {
  //       validationResult = (await validateProfileName({ name: value })).data;
  //       localStorage.setItem(
  // `validateProfileName::${JSON.stringify(value)}`, JSON.stringify(validationResult));
  //     }
  //   } catch (err) {
  //     throw new yup.ValidationError(
  //       'Unknown error',
  //       value,
  //       context.path,
  //     );
  //   }

  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const k of Object.keys(validationResult)) {
  //     if (!validationResult[k]) {
  //       throw new yup.ValidationError(
  //         t(`name.${k}Error`),
  //         value,
  //         context.path,
  //       );
  //     }
  //   }

  //   return true;
  // }, [t, validateProfileName]);

  const schema = useMemo(
    () => yup.object().shape({
      name: yup
        .string()
        .label(t('name.label'))
        .trim()
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      name: data?.name ?? '',
    }),
    [data],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={onComplete}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <TextFormControl
                  autoComplete="given-name"
                  helperText={t('name.helperText')}
                  label={t('name.label')}
                  name="name"
                  type="text"
                />
              </VStack>
            </Container>

            <TelegramMainButton
              color={isValid ? '#424242' : '#F44336'}
              isLoading={isValidating || isSubmitting}
              onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                setValidateAll(true);
                handleSubmit();
              }}
              text={isValid ? t('nextButton.default') : t('nextButton.invalid')}
              textColor="#FFFFFF"
            />
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function NameForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <NameFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
