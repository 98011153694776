enum EyeColor {
  AMBER = 'AMBER',
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  GRAY = 'GRAY',
  GREEN = 'GREEN',
  HAZEL = 'HAZEL',
}

export default EyeColor;
