import { Center, useDisclosure } from '@chakra-ui/react';
import { orderBy, query } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import {
  getProfileVersionsCollectionRef,
  ProfileVersionStatus,
} from '../../common/collections/Profiles';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ProfileForm from './ProfileForm';
import ProfileVersionAbsent from './ProfileVersionAbsent';
import ProfileVersionApproved from './ProfileVersionApproved';
import ProfileVersionReceived from './ProfileVersionReceived';
import ProfileVersionRejected from './ProfileVersionRejected';

export function WelcomeScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'WelcomeScreen' }); }, []);

  const myProfileRef = useMyProfileRef();

  const { data: profileVersionsSnap } = useFirestoreCollection(
    query(
      getProfileVersionsCollectionRef(myProfileRef),
      orderBy('sentAt', 'desc'),
    ),
  );

  const lastProfileVersionSnap = useMemo(
    () => (profileVersionsSnap.docs.length
      ? profileVersionsSnap.docs[0]
      : undefined),
    [profileVersionsSnap.docs],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const navigate = useNavigate();
  const handleContinueClick = useCallback(
    () => {
      navigate('/');
    },
    [navigate],
  );

  window.Telegram.WebApp.ready();

  if (isOpen) {
    return (
      <ProfileForm lastProfileVersionSnap={lastProfileVersionSnap} onBack={onClose} />
    );
  }

  if (lastProfileVersionSnap?.data()?.status === ProfileVersionStatus.APPROVED) {
    return (
      <ProfileVersionApproved
        lastProfileVersionSnap={lastProfileVersionSnap}
        onContinueClick={handleContinueClick}
      />
    );
  }

  if (lastProfileVersionSnap?.data()?.status === ProfileVersionStatus.SENT) {
    return (
      <ProfileVersionReceived
        lastProfileVersionSnap={lastProfileVersionSnap}
      />
    );
  }

  if (lastProfileVersionSnap?.data()?.status === ProfileVersionStatus.REJECTED) {
    return (
      <ProfileVersionRejected
        lastProfileVersionSnap={lastProfileVersionSnap}
        onReapplyClick={onOpen}
      />
    );
  }

  return (
    <ProfileVersionAbsent
      onApplyClick={onOpen}
    />
  );
}

export default function WelcomeScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <WelcomeScreenMain />
      </Suspense>
    </Catch>
  );
}
