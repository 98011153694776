import { Grid, useCheckboxGroup, UseCheckboxGroupProps } from '@chakra-ui/react';

import RelationshipStyle from '../../common/RelationshipStyle';
import MonogamyCard from './MonogamyCard';
import PolyamoryCard from './PolyamoryCard';
import PolyandryCard from './PolyandryCard';
import PolygynyCard from './PolygynyCard';

export interface Props {
  label: string;
  name: string;
}

export default function RelationshipStyleCheckboxGroup(props: UseCheckboxGroupProps) {
  const { getCheckboxProps } = useCheckboxGroup(props);

  const radioMonogamy = getCheckboxProps({ value: RelationshipStyle.MONOGAMY });
  const radioPolyamory = getCheckboxProps({ value: RelationshipStyle.POLYAMORY });
  const radioPolygyny = getCheckboxProps({ value: RelationshipStyle.POLYGYNY });
  const radioPolyandry = getCheckboxProps({ value: RelationshipStyle.POLYANDRY });

  return (
    <Grid
      gap={4}
      templateColumns="repeat(2, 1fr)"
      templateRows="repeat(2, 1fr)"
    >
      <MonogamyCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioMonogamy}
      />

      <PolyamoryCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioPolyamory}
      />

      <PolygynyCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioPolygyny}
      />

      <PolyandryCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioPolyandry}
      />
    </Grid>
  );
}
