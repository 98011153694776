import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  refEqual,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

export default function useDocumentSnapshot<T>(nextReference: DocumentReference<T> | undefined) {
  const [
    currentReference,
    setCurrentReference,
  ] = useState<DocumentReference<T> | undefined>(nextReference);

  useEffect(
    () => {
      if (
        currentReference === undefined
        || nextReference === undefined
        || !refEqual(currentReference, nextReference)
      ) {
        setCurrentReference(nextReference);
      }
    },
    [currentReference, nextReference],
  );

  const [snap, setSnap] = useState<DocumentSnapshot<T>>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(
    () => {
      if (currentReference !== undefined) {
        return onSnapshot(currentReference, setSnap, setError);
      }

      setSnap(undefined);
      setError(undefined);

      return () => { };
    },
    [currentReference],
  );

  return { error, snap };
}
