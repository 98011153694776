import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useTripsCollectionRef } from '../../common/collections/Trips';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import TripForm from './TripForm';

export function TripUpdateScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  useEffect(() => { mixpanel.track_pageview({ screen: 'TripCreateScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('TripCreateScreen');

  const { tripId } = useParams<{ tripId: string }>();

  const tripsCollectionRef = useTripsCollectionRef();
  const tripRef = useMemo(
    () => doc(tripsCollectionRef, tripId),
    [tripId, tripsCollectionRef],
  );

  const { data: tripSnap } = useFirestoreDoc(tripRef);

  if (!tripSnap.exists()) {
    throw new Error('Trip does not exist');
  }

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const onComplete = useCallback(
    () => {
      navigate(`/trips/${tripRef.id}`);
    },
    [navigate, tripRef.id],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          {window.Telegram.WebApp.initData === '' && (
            <IconButton
              aria-label={t('backButton.default')}
              as={Link}
              icon={<Icon as={LuChevronLeft} />}
              to=".."
            />
          )}

          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {tripDoc.name}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <TripForm
          onComplete={onComplete}
          tripSnap={tripSnap}
        />
      </Container>
    </VStack>
  );
}

export default function TripUpdateScreen() {
  const { t } = useTranslation('TripCreateScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <TripUpdateScreenMain />
      </Suspense>
    </Catch>
  );
}
