import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import { ChangeEvent, useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export interface Props {
  label: string;
  name: string;
  options: Record<string, string>;
}

export default function SelectFormControl({
  label,
  name,
  options,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<string>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(e.target.value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <Select onChange={handleChange} value={input.value}>
        {_.toPairs(options).map(([key, value]) => (
          <option key={key} value={key}>{value}</option>
        ))}
      </Select>
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
