import { encode } from 'blurhash';

const getBlurHash = (img: HTMLImageElement): string => {
  let width: number = img.naturalWidth;
  let height: number = Math.round((img.naturalWidth / 9) * 16);
  let x = 0;
  let y: number = -Math.round((img.naturalHeight - height) / 2);

  if (img.naturalWidth / img.naturalHeight > 9 / 16) {
    height = img.naturalHeight;
    width = Math.round((img.naturalHeight / 16) * 9);
    y = 0;
    x = -Math.round((img.naturalWidth - width) / 2);
  }

  const canvas = document.createElement('canvas');
  canvas.width = 180;
  canvas.height = 320;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('2d context is not available');
  }

  ctx.drawImage(img, x, y, 180, 320);

  ctx.save();

  const imageData = ctx.getImageData(0, 0, 180, 320);
  const blurHash = encode(imageData.data, imageData.width, imageData.height, 5, 8);

  return blurHash;
};

export default getBlurHash;
