import { Image, ImageProps } from '@chakra-ui/react';
import { ref } from 'firebase/storage';
import { useStorage, useStorageDownloadURL } from 'reactfire';

import { StoragePicture } from '../common/StoragePicture';
import useBlur from '../hooks/useBlur';

export type Props = {
  picture: StoragePicture;
} & ImageProps;

export default function StoragePictureImage({
  picture, ...imageProps
}: Props) {
  const storage = useStorage();
  const { data: src } = useStorageDownloadURL(
    ref(storage, picture.storageRef),
    { suspense: false },
  );

  const fallbackSrc = useBlur({
    blurHash: picture.blurHash,
    height: 32,
    width: 18,
  });

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
      fallbackSrc={fallbackSrc}
      src={src}
    />
  );
}
