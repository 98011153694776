import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Wealth from '../common/Wealth';
import useShowError from '../hooks/useShowError';
import Catch from './Catch';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  helperText?: string;
  label: string;
  name: string;
}

export function WealthFormControlMain({
  helperText, label,
  name,
}: Props) {
  const { t } = useTranslation('Wealth');

  const showError = useShowError();

  const [input, meta, helper] = useField<number>(name);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(parseFloat(e.target.value))
        .catch(showError);
    },
    [helper, showError],
  );

  const handleSliderChange = useCallback(
    (value: number) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <VStack alignItems="stretch" spacing={4}>
        <Slider
          focusThumbOnChange={false}
          max={Wealth.MILLIONAIRE}
          min={Wealth.BROKE}
          onChange={handleSliderChange}
          step={1}
          value={input.value}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <SliderThumb />
        </Slider>

        <Select onChange={handleInputChange} value={input.value}>
          <option value={Wealth.BROKE}>{t('BROKE.label')}</option>
          <option value={Wealth.AVERAGE}>{t('AVERAGE.label')}</option>
          <option value={Wealth.RICH}>{t('RICH.label')}</option>
          <option value={Wealth.AFFLUENT}>{t('AFFLUENT.label')}</option>
          <option value={Wealth.WEALTHY}>{t('WEALTHY.label')}</option>
          <option value={Wealth.MILLIONAIRE}>{t('MILLIONAIRE.label')}</option>
        </Select>
      </VStack>

      {helperText ? (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      ) : null}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default function WealthFormControl(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <WealthFormControlMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
