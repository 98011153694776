const destinationLabels: string[] = [
  'ADRIATIC SEA',
  'ADVENTURE',
  'AEGEAN SEA',
  'AFRICA',
  'ALPINE',
  'ANCIENT',
  'ANDAMAN SEA',
  'ANTARCTIC OCEAN',
  'ANTARCTICA',
  'ARABIAN SEA',
  'ARCHAEOLOGY',
  'ARCHITECTURE',
  'ARCTIC OCEAN',
  'ART',
  'ARTIFICIAL ISLAND',
  'ARTISAN',
  'ASIA',
  'ATLANTIC OCEAN',
  'BALTIC SEA',
  'BAMBOO',
  'BATHS',
  'BEACH',
  'BEDOUIN',
  'BERING SEA',
  'BIKING',
  'BLACK SAND',
  'BLACK SEA',
  'BOATING',
  'BOHO',
  'BOUTIQUE',
  'BRIDGE',
  'CAFE',
  'CAMEL',
  'CAMPING',
  'CANAL',
  'CARIBBEAN SEA',
  'CARNIVAL',
  'CASPIAN SEA',
  'CASTLE',
  'CAVE',
  'CHATEAU',
  'CHIC',
  'CHURCH',
  'CITY',
  'CLASSICAL',
  'CLIFFSIDE',
  'COASTLINE',
  'COBBLESTONE',
  'COFFEE',
  'COLONIAL',
  'CORAL REEF',
  'CORAL SEA',
  'COUNTRYSIDE',
  'COVE',
  'COZY',
  'CULTURE',
  'DANCE',
  'DESERT',
  'DESIGN',
  'DINING',
  'DIVERSITY',
  'DRAMATIC',
  'EAST SEA',
  'ECO-RESORT',
  'ELEGANT',
  'EUROPE',
  'EXCLUSIVE',
  'EXOTIC',
  'EXPLORATION',
  'FASHION',
  'FESTIVALS',
  'FJORD',
  'FOOD',
  'FRESCO',
  'GARDEN',
  'GEYSER',
  'GLACIER',
  'GLAMPING',
  'GOLF',
  'GONDOLA',
  'GOTHIC',
  'GOURMET',
  'GRANDEUR',
  'HARBOUR',
  'HELICOPTER',
  'HIKING',
  'HISTORIC',
  'HONEYMOON',
  'HOT AIR BALLOONS',
  'HOTEL',
  'INDIAN OCEAN',
  'ISLAND',
  'ISOLATED',
  'JUNGLE',
  'LAKE',
  'LANDMARK',
  'LANDSCAPE',
  'LUXURY',
  'MARINA',
  'MARKET',
  'MEDITATIVE',
  'MEDITERRANEAN SEA',
  'MODERN',
  'MONASTERY',
  'MONSOON',
  'MONUMENTS',
  'MOSAIC',
  'MOUNTAIN',
  'MUSEUM',
  'MUSIC',
  'MYSTICAL',
  'NARROW STREETS',
  'NATIONAL PARK',
  'NATURE',
  'NIGHTLIFE',
  'NORTH AMERICA',
  'NORTH SEA',
  'NORTHERN LIGHTS',
  'NOSTALGIC',
  'OASIS',
  'OCEAN',
  'OCEANIA',
  'OFF-ROAD',
  'OUTDOORS',
  'OVERWATER BUNGALOW',
  'PACIFIC OCEAN',
  'PALACE',
  'PARK',
  'PARTY',
  'PHOTOGRAPHY',
  'PRIVATE',
  'RAINFOREST',
  'RED SEA',
  'RELAXATION',
  'RESORT',
  'RETREAT',
  'RIVER',
  'ROMANTIC',
  'RUSTIC',
  'SAFARI',
  'SAND DUNES',
  'SAVANNA',
  'SCENIC',
  'SEA VIEW',
  'SEAFOOD',
  'SERENE',
  'SHOPPING',
  'SHRINE',
  'SKIING',
  'SKYLINE',
  'SNORKELING',
  'SNOW',
  'SOUTH AMERICA',
  'SOUTH CHINA SEA',
  'SPA',
  'SPIRITUAL',
  'STEPPE',
  'SUNRISE',
  'SUNSET',
  'SURFING',
  'TAPAS',
  'TEA HOUSE',
  'TEMPLE',
  'TRADITIONAL',
  'TRANQUIL',
  'TREKKING',
  'TRENDY',
  'TROPICAL',
  'TULIPS',
  'TUNDRA',
  'TURQUOISE WATER',
  'UNESCO',
  'UNIQUE',
  'URBAN',
  'VIBRANT',
  'VIEW',
  'VILLA',
  'VILLAGE',
  'VINEYARD',
  'VINTAGE',
  'VOLCANO',
  'WALKING',
  'WALL',
  'WATERFALL',
  'WATERFRONT',
  'WELLNESS',
  'WHALES',
  'WHITE BUILDINGS',
  'WHITE SAND',
  'WILDLIFE',
  'WINDMILLS',
  'WINE',
  'WORLD HERITAGE',
  'YACHT',
  'YOGA',
  'ZEN',
];

export default destinationLabels;
